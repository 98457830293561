import { ActionIcon, Avatar, Badge, Box, Button, Checkbox, CloseButton, Divider, Group, Input, Paper, Space, Text } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconFilter, IconPhoto, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { ErpInvoiceApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { FormLayout } from "../layout";
import { SelectFilter } from "../select-filter";
import { ColumnType, Table } from "../table";

export function InvoiceProductPackageForm({ values, setAction, action, setFieldValue }: any) {
  const [selected, setSelected] = useState<any[]>(values?.lines ?? []);
  const [filters, setFilters] = useState({ query: "", businessId: values?.receiverBusinessId, brandId: null, categoryId: null });
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { vatTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [query, setQuery] = useState<string>("");

  const [queryDelay, setQueryDelay] = useDebouncedState<any>(query, 800);

  const [brandQueryDelay, setBrandQueryDelay] = useDebouncedState<string>("", 800);
  const { data: brandSelects = [] } = useSwr<any[]>(
    `/erp/brand/select?query=${JSON.stringify({
      query: brandQueryDelay,
      businessId: user?.currentBusiness.id,
    })}`,
    async () =>
      await ErpInvoiceApi.brandSelect({
        query: brandQueryDelay,
        businessId: user?.currentBusiness.id,
      }),
    {
      fallback: [],
    },
  );

  const [categoriesQueryDelay, setCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const { data: categoriesSelects = [] } = useSwr<any[]>(
    `/erp/category/select?query=${JSON.stringify({
      query: categoriesQueryDelay,
      businessId: user?.currentBusiness.id,
    })}`,
    async () =>
      await ErpInvoiceApi.categorySelect({
        query: categoriesQueryDelay,
        businessId: user?.currentBusiness.id,
      }),
    {
      fallback: [],
    },
  );

  const columns = useHeader({ selected, setSelected, vatTypes });

  const onClearFilter = () => {
    setFilters({ query: "", businessId: values?.receiverBusinessId, brandId: null, categoryId: null });
  };

  useEffect(() => {
    setQueryDelay(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper px={"lg"}>
      <FormLayout title="Багцаар сонгох" subTitle="Багцаар сонгох" extra={[<CloseButton onClick={() => setAction([])} type="submit" key={"close"} />]}>
        <Paper>
          <Divider mb={"xs"} />
          <Group>
            <Input.Wrapper label="">
              <Input
                w={"340px"}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                placeholder="Барааны нэрээр хайх..."
                leftSection={<IconSearch size={18} />}
              />
            </Input.Wrapper>
            <Group>
              <SelectFilter
                onSearchChange={(e) => {
                  setCategoriesQueryDelay(e ?? "");
                }}
                label="Категори"
                value={filters?.categoryId ?? ""}
                onChange={(e: any) => {
                  setFilters({
                    ...filters,
                    categoryId: e,
                  });
                }}
                data={
                  categoriesSelects?.map((item: any) => {
                    return {
                      name: item.name,
                      code: item.id,
                    };
                  }) ?? []
                }
              />
              <SelectFilter
                onSearchChange={(e) => setBrandQueryDelay(e)}
                label="Брэнд"
                value={filters?.brandId ?? ""}
                onChange={(e: any) => {
                  setFilters({
                    ...filters,
                    brandId: e,
                  });
                }}
                data={
                  brandSelects?.map((item: any) => {
                    return {
                      name: item.name,
                      code: item.id,
                    };
                  }) ?? []
                }
              />
              <ActionIcon onClick={onClearFilter} color="" variant="light" size={36}>
                <IconFilter />
              </ActionIcon>
            </Group>
          </Group>
          <Space h={"sm"} />
          <Input.Wrapper label={`Сонгосон бараа ${selected.length} байна.`} required>
            <div>
              <Divider mt="xs" />
            </div>
            <Table
              filters={{ ...filters, query: queryDelay }}
              columns={columns}
              name={`invoice.package`}
              loadData={(data: any) => ErpInvoiceApi.variant_list(data!)}
            />
          </Input.Wrapper>
          <Divider my="lg" />
          <Group justify="right">
            <Button
              onClick={() => {
                setFieldValue("lines", selected);
                setAction([]);
              }}>
              Болсон
            </Button>
          </Group>
        </Paper>
      </FormLayout>
    </Paper>
  );
}

const useHeader = ({ selected, setSelected }: { selected: any; setSelected: any; vatTypes: IReference[] }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "50px",
  },
  {
    title: "",
    renderTitle: (data) => {
      return (
        <>
          <Checkbox
            value={data.rows.every((objToCheck: any) => selected.some((item: any) => item.id === objToCheck.id)) ? "on" : "off"}
            checked={data.rows.every((objToCheck: any) => selected.some((item: any) => item.id === objToCheck.id))}
            onChange={(e) => {
              if (e.target.value === "off")
                setSelected(
                  [...selected, ...data.rows]
                    .filter((item, index, self) => index === self.findIndex((obj) => obj.id === item.id))
                    .map((item: any) => ({
                      ...item,
                      quantity: 1,
                      nameMon:
                        item.nameMon +
                        ((item?.optionValues?.length ?? 0) > 0 ? ", " : "") +
                        item.optionValues?.map((item: any) => {
                          return " " + item.name;
                        }),
                    })),
                );
              else if (e.target.value === "on") {
                setSelected(selected.filter((item1: any) => !data.rows.some((item2: any) => item2.id === item1.id)));
              }
            }}
          />
        </>
      );
    },
    render: (record) => (
      <Box w={"max-content"}>
        <Checkbox
          checked={selected.some((item: any) => item.id === record.id)}
          onChange={() => {
            if (selected.some((item: any) => item.id === record.id)) {
              setSelected(selected.filter((item: any) => item.id !== record.id));
            } else {
              setSelected([
                ...selected,
                {
                  ...record,
                  quantity: 1,
                  nameMon:
                    record.nameMon +
                    (record.optionValues?.length > 0 ? ", " : "") +
                    record.optionValues?.map((item: any) => {
                      return " " + item.name;
                    }),
                },
              ]);
            }
          }}
        />
      </Box>
    ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => (
      <Avatar src={`${record?.image ?? ""}`} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    width: 140,
    render: (record) => <Text size="sm">{record?.skuCode ?? "-"}</Text>,
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    sorter: true,
    dataIndex: "category",
    width: 140,
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.nameMon}
        {record.optionValues?.map((item: any) => {
          return ", " + item?.name + " ";
        }) ?? "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit",
    width: 140,
    render: (record) => <Text size="sm">{record?.unit ?? "-"}</Text>,
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    width: 140,
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record?.price)}
      </Text>
    ),
  },
  {
    title: "НӨАТ",
    sorter: true,
    dataIndex: "hasVat",
    width: 140,
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record?.vatAmount ?? 0)}
      </Text>
    ),
  },
  {
    title: "НХАТ",
    sorter: true,
    dataIndex: "hasTax",
    width: 140,
    render: (record) => <Badge color={record.hasTax ? "green" : "gray"}>{record.hasTax ? "Тийм" : "Үгүй"}</Badge>,
  },
];
