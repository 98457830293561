import { Button, CloseButton, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { FileUpload } from "../upload/upload-file";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().required("Заавал бөглөнө!").nullable(),
  url: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IUser = {
  avatar: string | undefined;
  firstName: string | undefined;
  id?: string | undefined;
  lastName: string | undefined;
};

type IFileItem = {
  name: string | undefined;
  description: string | undefined;
  url: string | undefined;
  date: string | undefined;
  attachedUser?: IUser | undefined;
  id: string;
  createdAt: Date | undefined;
};

export function InvoiceAttachmentModalForm({ action, setAction, values: propsValues, setFieldValue: setFieldValueProps }: any) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const formRef = useRef<any>(null);

  const [data] = useState<IFileItem>({
    name: undefined,
    description: undefined,
    url: undefined,
    date: undefined,

    attachedUser: {
      lastName: user?.lastName,
      firstName: user?.firstName,
      avatar: user?.avatar,
    },
    ...(action[0] === "edit" || action[0] === "details" ? action[1] : {}),
  });

  const now = new Date();

  const onSubmit = async (values: any) => {
    const id = uuidv4();

    if (action[0] === "add") {
      setFieldValueProps("attachments", [...(propsValues.attachments || []), { ...values, id, createdAt: new Date() }]);
      setAction([]);
    } else {
      setFieldValueProps("attachments", [...propsValues.attachments.filter((f: any) => f.id !== values.id), { ...values, createdAt: new Date() }]);
      setAction([]);
    }
  };

  return (
    <Form ref={formRef} validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {({ values, errors, setFieldValue }) => {
        return (
          <Paper>
            <FormLayout
              my={0}
              title={action[0] === "details" ? "Хавсралт файл" : action[0] === "edit" ? "Хавсралт файл засах" : "Хавсралт файл нэмэх"}
              subTitle={action[0] === "details" ? "Хавсралт файл дэлгэрэнгүй" : action[0] === "edit" ? "Хавсралт файл засах" : "Хавсралт файл нэмэх"}
              extra={[<CloseButton onClick={() => setAction([])} type="submit" key={1}></CloseButton>]}>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <Input.Wrapper label="Хавсаргасан">
                    <Text c="indigo" fw={500} size="sm" tt="capitalize">
                      {values?.attachedUser?.lastName ? `${values?.attachedUser?.lastName[0]}. ` : ""} {values?.attachedUser?.firstName || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <Input.Wrapper label="Хавсаргасан огноо">
                    <Text c="indigo" fw={500} size="sm">
                      {values?.createdAt ? dateTimeFormat(values.createdAt) : dateTimeFormat(now)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <TextField disabled={action[0] === "details"} name="name" placeholder="Нэр" label="Нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6 }}>
                  <FileUpload
                    disabled={action[0] === "details"}
                    value={values?.url}
                    error={!values?.url && errors?.url}
                    onChange={(e) => setFieldValue("url", e?.url)}
                    name="url"
                    placeholder={values?.url ? `файл: ${values?.url?.slice(66, 96)}` : "Хавсралт"}
                    label="Хавсралт"
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField disabled={action[0] === "details"} name="description" placeholder="Тайлбар" label="Тайлбар" required />
                </Grid.Col>
              </Grid>
              <Divider my="lg" />
              <Group align="right">
                <Button
                  disabled={action[0] === "details"}
                  onClick={async () => {
                    let res = await formRef.current.submit();
                    if (res) {
                      onSubmit(res);
                    }
                  }}>
                  Болсон
                </Button>
              </Group>
            </FormLayout>
          </Paper>
        );
      }}
    </Form>
  );
}
