import { Avatar, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceApi } from "../../apis";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IInvoiceHistoryItem } from "../../interfaces/IInvoiceHistoryItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { PopoverText } from "../../components/popover-text/popover-text";

export const InvoicePaidHistory = ({ data }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const { overdueStatus, paymentMethod, invoiceHistoryTypes, invoiceStatus, invoicePaymentStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const INV_NOTE_PAY = usePermission("INV_NOTE_PAY");

  const [filters] = useState({
    invoiceId: `${params?.id}`,
  });

  const columns = useHeader({
    Tag,
    invoiceStatus,
    overdueStatus,
    invoiceHistoryTypes,
    paymentMethod,
    onClick: (key, record) => {
      if (key === "detail") navigate(`/buyer-invoice/${record?.id}/details`);
    },
  });

  if (!data) return <LoadingOverlay visible />;

  return (
    <div>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нэхэмжлэх дугаар">
            <Text c="brand" fz="sm" fw={500}>
              # {data?.refCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Партнерийн нэр">
            <Flex gap={8}>
              <Text c="brand" fz="sm" fw={500}>
                #{data?.receiverBusiness?.partner?.refCode ?? "-"}
              </Text>
              <Text fz="sm" fw={500}>
                {data?.receiverBusiness?.partner?.businessName ?? "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нийлүүлэгч бизнесийн нэр">
            <Flex gap={8}>
              <Text c="brand" fz="sm" fw={500}>
                #{data?.senderBusiness?.refCode ?? "-"}
              </Text>
              <Text fz="sm" fw={500}>
                {data?.senderBusiness?.profileName ?? "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нэхэмжлэх төлөв">
            <div>
              {data?.invoiceStatus
                ? Tag(
                    invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.color ?? "blue",
                    invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.name ?? "-",
                  )
                : "-"}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Төлбөрийн төлөв">
            <div>
              {data?.paymentStatus
                ? Tag(
                    invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.color ?? "blue",
                    invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.name ?? "-",
                  )
                : "-"}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Хугацаа хэтрэлт төлөв">
            <div>
              {data?.overdueStatus
                ? Tag(
                    overdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.color ?? "blue",
                    overdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.name ?? "-",
                  )
                : "-"}
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Баталсан дүн">
            <Text c="brand" fz="sm" fw={500}>
              {currencyFormat(data?.confirmedAmount)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Төлсөн дүн">
            <Text c="brand" fz="sm" fw={500}>
              {currencyFormat(data?.paidAmount ?? 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Үлдэгдэл төлбөр">
            <Text c="indigo" fz="lg" fw={500}>
              {currencyFormat(data?.amountToPay ?? 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text c="gray" fz={"lg"} fw={600} my="sm">
            Нэхэмжлэх төлөлтийн түүх
          </Text>
          {INV_NOTE_PAY.hasAccess ? (
            <Table name="InvoiceApi.history" columns={columns} filters={filters} loadData={(data) => InvoiceApi.history(data!)} />
          ) : (
            INV_NOTE_PAY.accessDenied()
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
};

const useHeader = ({
  onClick,
  Tag,
  overdueStatus,
  invoiceHistoryTypes,
  invoiceStatus,
  paymentMethod,
}: {
  onClick: (key: string, record: IInvoiceHistoryItem) => void;
  Tag: any;
  overdueStatus: IReference[];
  invoiceHistoryTypes: IReference[];
  invoiceStatus: IReference[];
  paymentMethod: IReference[];
}): ColumnType<IInvoiceHistoryItem>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Гүйлгээний огноо, цаг",
    render: (record) => <Text size="sm">{dateTimeFormat(record.createdAt)}</Text>,
    width: "30px",
  },
  {
    title: "Гүйлгээний лавлах",
    dataIndex: "salesOrder.refCode",
    render: (record) => {
      return <Text size="sm">{record?.trxRefCode ?? "-"}</Text>;
    },
  },
  {
    title: "Гүйлгээний дүн",
    dataIndex: "category",
    render: (record) => {
      return (
        <Text ta="end" size="sm" style={{ whiteSpace: "nowrap" }}>
          {currencyFormat(record?.trxAmount)}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    dataIndex: "description",
    render: (record) => {
      return <PopoverText children={record?.description || "-"} />;
    },
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    dataIndex: "paymentMethod",
    render: (record) => (
      <Text size="sm" w="max-content">
        {paymentMethod.find((i: IReference) => i.code === record.paymentMethod)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн төрөл",
    dataIndex: "type",
    render: (record) => {
      return Tag(
        invoiceHistoryTypes?.find((item: IReference) => item.code === record?.type)?.color ?? "blue",
        invoiceHistoryTypes?.find((item: IReference) => item.code === record?.type)?.name ?? "-",
      );
    },
  },
  {
    title: "Төлбөрийн төлөв",
    dataIndex: "overdueStatus",
    render: (record) => {
      return Tag(
        overdueStatus?.find((item: IReference) => item.code === record?.overdueStatus)?.color ?? "blue",
        overdueStatus?.find((item: IReference) => item.code === record?.overdueStatus)?.name ?? "-",
      );
    },
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    dataIndex: "invoiceStatus",
    render: (record) => {
      return Tag(
        invoiceStatus?.find((item: IReference) => item.code === record?.invoiceStatus)?.color ?? "blue",
        invoiceStatus?.find((item: IReference) => item.code === record?.invoiceStatus)?.name ?? "-",
      );
    },
  },
  {
    title: "Төлбөр төлсөн",
    render: (record) => {
      return record.actionUser ? (
        <Flex align="center" gap={10} w="max-content">
          <Avatar src={record.actionUser.avatar} size={32} />
          <Text tt={"capitalize"} size="sm">
            {record.actionUser.lastName ? record.actionUser.lastName[0] + "." : ""}
            {record.actionUser.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Төлсөн дансны дугаар",
    dataIndex: "debitAcc?.number",
    render: (record) => <Text size="sm">{record?.creditAcc?.number ?? "-"}</Text>,
  },
  {
    title: "Төлсөн дансны нэр",
    dataIndex: "debitAcc?.name",
    render: (record) => <Text size="sm">{record?.creditAcc?.name ?? "-"}</Text>,
  },
  {
    title: "Хүлээн авсан дансны дугаар",
    dataIndex: "creditAcc?.number",
    render: (record) => <Text size="sm">{record?.debitAcc?.number ?? "-"}</Text>,
  },
  {
    title: "Хүлээн авсан дансны нэр",
    dataIndex: "creditAcc?.name",
    render: (record) => <Text size="sm">{record?.debitAcc?.name ?? "-"}</Text>,
  },
];
