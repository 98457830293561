import { LoadingOverlay } from "@mantine/core";
import qs from "qs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AuthApi, GeneralApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";

export function Access() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  React.useEffect(() => {
    (async () => {
      try {
        const params = qs.parse(location.search);
        dispatch(authChange({ accessToken: params["?token"] } as any));

        const res = await GeneralApi.init();
        await dispatch(initGeneral(res));
        const auth = await AuthApi.me();
        await dispatch(authMe(auth));

        navigate("/");
      } catch (err: any) {
        err?.message && message.error(err.message);
      }
    })();
  }, [accessToken, dispatch, location, navigate]);

  return <LoadingOverlay visible />;
}
