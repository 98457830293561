import { ActionIcon, Anchor, Avatar, Divider, Flex, Grid, Input, Select, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCalendar, IconCoin, IconFileText, IconFileTypePdf, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { NetworkApi, SettlementApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";

export const RequestBuyer = () => {
  const { currencies, scfRequestStatus, fundingInstructionStatus, settlementStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters, setFilters] = useState<any>({ month: new Date(), regNumber: null, businessId: null });
  const [load, setLoad] = useState<string[]>([]);
  const navigate = useNavigate();

  const [regNumberQuery, setRegNumberQuery] = useState<string>("");
  const [regNumberFilterDelay] = useDebouncedValue<string>(regNumberQuery, 800);

  const [customerQuery, setCustomerQuery] = useState<string>("");
  const [customerFilterDelay] = useDebouncedValue<string>(customerQuery, 800);

  const { data: customerSelects = [] } = useSwr<any[]>(
    `/erp/goods/customer/select?query=${JSON.stringify(customerFilterDelay)}`,
    async () =>
      await NetworkApi.select({
        query: customerFilterDelay ?? "",
      }),
    {
      fallback: [],
    },
  );
  const Pdf = async (id: string) => {
    try {
      let res = await SettlementApi.pdf(id);
      window.open(res);
    } catch (error: any) {
      message.error(error.message ?? "Үйлдэл амжилтгүй!");
    }
    setLoad([...load.filter((item: string) => item !== id)]);
  };

  const columns = useHeader({
    currencies,
    scfRequestStatus,
    Tag,
    load,
    fundingInstructionStatus,
    onClick: (key, record) => {
      switch (key) {
        case "respond":
          navigate(`/settlement/${record.id}`, { state: { ...record, action: "" } });
          break;
        case "details":
          navigate(`/settlement/${record.id}`, { state: { ...record, action: "DETAILS" } });
          break;
        case "pdf":
          setLoad([...load, `${record.id}`]);
          Pdf(`${record.id}`);

          break;

        default:
          break;
      }
    },
    settlementStatus,
  });

  return (
    <div>
      <Divider />
      <Grid my={"md"}>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <Input.Wrapper label="Тооцоо нийлэх сар">
            <DatePickerInput
              leftSection={<IconCalendar size={"1.2rem"} />}
              value={filters.month ? new Date(filters.month) : null}
              onChange={(e) => setFilters({ ...filters, month: e })}
              placeholder="2023-01-01"
              valueFormat="YYYY-MM-DD"
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <Input.Wrapper label="Харилцагчийн нэрээр хайх">
            <Select
              searchValue={customerQuery}
              onSearchChange={(e) => {
                setCustomerQuery(e);
              }}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  businessId: e,
                });
              }}
              searchable
              nothingFoundMessage="Хайлт олдсонгүй."
              data={
                customerSelects?.map((item: any) => {
                  return {
                    label: item?.profileName ?? "",
                    value: item?.id ?? "",
                    refCode: item?.refCode ?? "",
                    logo: item?.logo ?? "",
                  };
                }) ?? []
              }
              clearable
              placeholder="Нэрээр хайх"
              leftSection={<IconSearch color="gray" size={18} />}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <Input.Wrapper label="Харилцагчийн ТТД-аар хайх">
            <Input
              leftSection={<IconSearch color="gray" size={18} />}
              type="number"
              value={regNumberQuery}
              onChange={(e: any) => {
                setRegNumberQuery(e.target.value);
              }}
              placeholder="Харилцагчийн ТТД-аар хайх"
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Table
        name="requests.list.BUYER"
        columns={columns}
        filters={{ ...filters, regNumber: regNumberFilterDelay }}
        loadData={(data) => SettlementApi.received(data!)}
      />
    </div>
  );
};

const useHeader = ({
  onClick,
  currencies,
  scfRequestStatus,
  Tag,
  fundingInstructionStatus,
  load,
  settlementStatus,
}: {
  onClick: (key: string, record: any) => void;
  currencies: any;
  scfRequestStatus: any;
  Tag: any;
  fundingInstructionStatus: IReference[];
  settlementStatus: IReference[];
  load: string[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => (
      <Flex gap={10} align="center">
        {record.settlementStatus !== "NONE" && (
          <Tooltip label="Дэлгэрэнгүй">
            <ActionIcon color="" onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>
        )}
        {record.settlementStatus === "SENT" && (
          <Tooltip label="Тооцоо зөвшөөрөх">
            <ActionIcon color="" onClick={() => onClick("respond", record)}>
              <IconCoin />
            </ActionIcon>
          </Tooltip>
        )}
        {record.settlementStatus === "CONFIRMED" && (
          <Tooltip label="PDF">
            <ActionIcon loading={load.some((item: string) => item === record.id)} onClick={() => onClick("pdf", record)} variant="light" color="indigo">
              <IconFileTypePdf />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Тооцооны баримт",
    width: "max-content",
    render: (record) => (
      <Anchor>
        <Text c="brand" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </Anchor>
    ),
  },
  {
    title: "Тооцооны сар",
    width: "max-content",
    render: (record) => <Text size="sm">{record?.month ?? "-"}</Text>,
  },
  {
    title: "Тооцооны статус",
    width: "max-content",
    render: (record) =>
      Tag(
        settlementStatus.find((i: IReference) => i.code === record.settlementStatus)?.color ?? "gray",
        settlementStatus.find((i: IReference) => i.code === record.settlementStatus)?.name ?? "-",
      ),
  },
  {
    title: "Илгээсэн огноо",
    render: (record) => (
      <Text size="sm" style={{ whiteSpace: "nowrap" }}>
        {record?.sentDate ? dateTimeFormat(record.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Тооцоо нийлсэн",
    width: "max-content",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.confirmedDate ? dateTimeFormat(record?.confirmedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Нийлүүлэгч - санхүү",
    width: "max-content",
    render: (record) => (
      <Flex gap={10} align="center" w="max-content">
        <Avatar src={record?.financeStaff?.avatar} />
        <Text tt={"capitalize"} size="sm">
          {record?.financeStaff?.lastName ? record?.financeStaff?.lastName[0] + "." : ""}
          {record?.financeStaff?.firstName ?? "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нийлүүлэгч партнер",
    width: "max-content",
    render: (record) =>
      record?.senderBusiness ? (
        <Flex direction="column" w="max-content">
          <Anchor size="sm">{record?.senderBusiness?.partner?.businessName ?? "-"}</Anchor>
          <Text size="sm">#{record?.senderBusiness?.partner?.refCode ?? "-"}</Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    width: "max-content",
    render: (record) =>
      record?.senderBusiness ? (
        <Flex direction="column" w={"max-content"}>
          <Anchor size="sm">#{record?.senderBusiness?.refCode ?? "-"}</Anchor>
          <Text size="sm">{record?.senderBusiness?.profileName ?? "-"}</Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Үлдэгдэлтэй нэхэмжлэх",
    width: "max-content",
    render: (record) => (
      <Text ta="center" size="sm">
        {record?.lastInvCount}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэхийн үлдэгдэл дүн",
    width: "max-content",
    render: (record) => (
      <Text c="brand" ta="end" size="sm">
        {currencyFormat(record?.lastAmount)}
      </Text>
    ),
  },
];
