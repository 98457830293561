import { ActionIcon, Text, Tooltip } from "@mantine/core";
import { IconFileText } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NetworkSettlementApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const InvioceOpen = ({ filters }: { filters: any }) => {
  const { overdueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "details":
          navigate(`/customer-billing/${record.id}/history`, { state: record });

          break;

        default:
          break;
      }
    },
    overdueStatus,
  });

  return (
    <div>
      <Table name="invoice.open.list" columns={columns} filters={filters} loadData={(data) => NetworkSettlementApi.invoice(data!)} />
    </div>
  );
};

const useHeader = ({ onClick, overdueStatus }: { onClick: (key: string, record: any) => void; overdueStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Дэлгэрэнгүй">
        <ActionIcon color="" onClick={() => onClick("details", record)}>
          <IconFileText />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "Баталсан огноо",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {dateTimeFormat(record.confirmedDate)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => (
      <Text w="max-content" size="sm">
        #{record?.refCode}
      </Text>
    ),
  },
  {
    title: "Баталсан дүн",
    render: (record) => (
      <Text size="sm" ta="end">
        {currencyFormat(record.confirmedAmount)}
      </Text>
    ),
  },
  {
    title: "Төлсөн дүн",
    render: (record) => (
      <Text size="sm" ta="end">
        {currencyFormat(record.paidAmount)}
      </Text>
    ),
  },
  {
    title: "Үлдэгдэл төлбөр",
    render: (record) => (
      <Text c="brand" ta="end" size="sm">
        {currencyFormat(record.amountToPay)}
      </Text>
    ),
  },
  {
    title: "Хугацаа хэтрэлт",
    render: (record) => {
      return Tag(
        overdueStatus?.find((item: IReference) => item.code === record?.overdueStatus)?.color ?? "gray",
        overdueStatus?.find((item: IReference) => item.code === record.overdueStatus)?.name ?? record?.overdueStatus ?? "-",
      );
    },
  },
  {
    title: "Үлдсэн хоног",
    render: (record) => <Text size="sm">{dayjs(record.paymentDate).diff(dayjs(), "days")}</Text>,
  },
  {
    title: "Төлөх ёстой огноо",
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(record.paymentDate)}
      </Text>
    ),
  },
  {
    title: "Захиалгын дугаар",
    render: (record) => <Text size="sm">{record?.orderSalesCode ?? "-"}</Text>,
  },
];
