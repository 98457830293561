import { TextInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  required?: boolean;
  type?: string;
  readOnly?: boolean;
  rightSection?: any;
  rightSectionWidth?: any;
  w?: any;
  left?: any;
};

export function TextField({
  name,
  label,
  placeholder,
  disabled = false,
  required = false,
  type = "text",
  readOnly = false,
  rightSection = undefined,
  rightSectionWidth = undefined,
  w,
  left,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <TextInput
      left={left}
      w={w}
      rightSectionWidth={rightSectionWidth}
      rightSection={rightSection}
      readOnly={readOnly}
      type={type}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      withAsterisk={required}
    />
  );
}
