import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  types: [],
  basePriceTypes: [],
  businessTypes: [],
  categoryTypes: [],
  currencies: [],
  fieldTypes: [],
  groupPriceStatus: [],
  itemImageTypes: [],
  itemStatus: [],
  numberValueLabels: [],
  perTypes: [],
  priceChangeTypes: [],
  priceEndTypes: [],
  returnTypes: [],
  unitConvertTypes: [],
  unitSpaceLabels: [],
  unitWeightLabels: [],
  specialPriceStatus: [],
  brands: [],
  manufacturers: [],
  countries: [],
  distributors: [],
  tags: [],
  categories: [],
  suppliers: [],
  itemTypes: [],
  overdueStatus: [],
  invoiceStatus: [],
  staffs: [],
  bankAccounts: [],
  paymentMethod: [],
  invoicePaymentStatus: [],
  invoiceHistoryTypes: [],
  scfRequestStatus: [],
  fundingInstructionStatus: [],
  settlementStatus: [],
  paymentTermConfigTypes: [],
  paymentTermConditions: [],
  vatTypes: [],
  invoiceHistoryLogTypes: [],
  permissions: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const settlementStatusColors = ["gray", "green", "blue"];

      const {
        types,
        basePriceTypes,
        businessTypes,
        categoryTypes,
        currencies,
        fieldTypes,
        groupPriceStatus,
        itemImageTypes,
        itemStatus,
        numberValueLabels,
        perTypes,
        priceChangeTypes,
        priceEndTypes,
        returnTypes,
        unitConvertTypes,
        unitSpaceLabels,
        unitWeightLabels,
        specialPriceStatus,
        brands,
        manufacturers,
        countries,
        distributors,
        tags,
        categories,
        suppliers,
        itemTypes,
        overdueStatus,
        invoiceStatus,
        staffs,
        bankAccounts,
        paymentMethod,
        invoicePaymentStatus,
        invoiceHistoryTypes,
        scfRequestStatus,
        fundingInstructionStatus,
        settlementStatus,
        paymentTermConfigTypes,
        paymentTermConditions,
        vatTypes,
        invoiceHistoryLogTypes,
        permissions,
      } = payload;

      state.types = types;
      state.basePriceTypes = basePriceTypes;
      state.businessTypes = businessTypes;
      state.categoryTypes = categoryTypes;
      state.currencies = currencies;
      state.fieldTypes = fieldTypes;
      state.groupPriceStatus = groupPriceStatus;
      state.itemImageTypes = itemImageTypes;
      state.itemStatus = itemStatus;
      state.numberValueLabels = numberValueLabels;
      state.perTypes = perTypes;
      state.priceChangeTypes = priceChangeTypes;
      state.priceEndTypes = priceEndTypes;
      state.returnTypes = returnTypes;
      state.unitConvertTypes = unitConvertTypes;
      state.unitSpaceLabels = unitSpaceLabels;
      state.unitWeightLabels = unitWeightLabels;
      state.specialPriceStatus = specialPriceStatus;
      state.brands = brands;
      state.manufacturers = manufacturers;
      state.countries = countries;
      state.distributors = distributors;
      state.tags = tags;
      state.categories = categories;
      state.suppliers = suppliers;
      state.itemTypes = itemTypes;
      state.staffs = staffs;
      state.bankAccounts = bankAccounts;
      state.paymentMethod = paymentMethod;
      state.invoiceHistoryTypes = invoiceHistoryTypes;
      state.scfRequestStatus = scfRequestStatus;
      state.fundingInstructionStatus = fundingInstructionStatus;
      state.settlementStatus = settlementStatus.map((item: any, index: number) => {
        return {
          ...item,
          color: settlementStatusColors[index],
        };
      });
      state.overdueStatus = overdueStatus;
      state.invoiceStatus = invoiceStatus;
      state.invoicePaymentStatus = invoicePaymentStatus;
      state.paymentTermConfigTypes = paymentTermConfigTypes;
      state.paymentTermConditions = paymentTermConditions;
      state.vatTypes = vatTypes;
      state.invoiceHistoryLogTypes = invoiceHistoryLogTypes;
      state.permissions = permissions?.filter((item: any) => item.module === "INVOICE");

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral } = generalSlice.actions;
