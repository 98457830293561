import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const init = async () => {
  return httpRequest.get("/inv/general/init");
};

export const me = async () => {
  return httpRequest.get("/inv/auth/me");
};
