import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const respond = async (id: string) => {
  return httpRequest.put(`/inv/settlement/${id}/respond`);
};

export const send = async (id: string) => {
  return httpRequest.put(`/inv/settlement/${id}/send`);
};

export const received = async (data: any) => {
  return httpRequest.get("/inv/settlement/received", data);
};

export const sent = async (data: any) => {
  return httpRequest.get("/inv/settlement/sent", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/inv/settlement/${id}`);
};

export const pdf = async (id: string) => {
  return httpRequest.get(`/inv/settlement/${id}/pdf`);
};
