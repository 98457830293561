import { Accordion, Button, Group, LoadingOverlay, Paper, Text } from "@mantine/core";
import { createContext, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import * as yup from "yup";
import { InvoiceApi, NetworkApi, branchApi } from "../../apis";
import { Form } from "../../components/form";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { InvoiceAttachmentForm } from "../../components/sales-invoice/invoice-attachment-form";
import { InvoiceProductForm } from "../../components/sales-invoice/invoice-product-form";
import { InvoiceReceiverForm } from "../../components/sales-invoice/invoice-receiver-form";
import { InvoiceSenderForm } from "../../components/sales-invoice/invoice-sender-form";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/Message";

const schema = yup.object({
  senderFinUserId: yup.string().optional(),
  senderBranchId: yup.string().required("Заавал бөглөнө!").nullable(),
  senderAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  receiverBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
  paymentDate: yup.date().required("Заавал бөглөнө!").nullable(),
  receiverBranchId: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().nullable().optional(),
  shippingAmount: yup
    .number()
    .min(0, "0 -с их дүн оруулна уу!!")
    .max(1000000000, "1,000,000,000-с бага дүн оруулна уу!")
    .typeError("Заавал бөглөнө!")
    .nullable()
    .optional(),
  discountType: yup.string().required(" ").nullable().optional(),
  discountValue: yup.number().min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().optional(),
  additionalLines: yup
    .array()
    .of(
      yup.object().shape({
        isEdit: yup.boolean().optional(),
        additionalLines: yup.boolean().optional(),
        image: yup.string().optional(),
        discountValue: yup.number().typeError("Заавал бөглөнө!").optional(),
        discountType: yup.string().optional(),
        name: yup.string().required("Заавал бөглөнө!"),
        price: yup.number().moreThan(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
        quantity: yup.number().min(1, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
        unit: yup.string().required("Заавал бөглөнө!"),
      }),
    )
    .optional(),
  lines: yup
    .array()
    .of(
      yup.object().shape({
        isEdit: yup.boolean().optional(),
        additionalLines: yup.boolean().optional(),
        quantity: yup.number().min(1, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
        discountValue: yup.number().min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
        discountType: yup.string().required("Заавал бөглөнө!").nullable(),
      }),
    )
    .required("Заавал бөглөнө!")
    .nullable()
    .optional(),
});

type IFileItem = {
  name: string;
  description: string;
  url: string;
};

type IItem = {
  variantId: string;
  id?: string;
  variantUnitId: string;
  discountType: string;
  discountValue: number | string;
  hasVat: boolean;
  hasTax: boolean;
  quantity: number;
};

type IFormData = {
  senderFinUserId: string | undefined | any;
  senderStaffId: string | undefined;
  senderBranchId: string | undefined;
  senderAccId: string | undefined;
  receiverBusinessId: string | undefined;
  paymentDate: Date | string | undefined;
  receiverBranchId: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountValue: number;
  shippingAmount: number | undefined;
  send: boolean;
  attachments: IFileItem[];
  lines: any[];
  orderSalesCode: string | null;
  purchaseSalesCode: string | null;
};

const initData = {
  senderFinUserId: undefined,
  senderStaffId: undefined,
  senderBranchId: undefined,
  senderAccId: undefined,

  receiverBusinessId: undefined,
  paymentDate: undefined,
  receiverBranchId: undefined,

  description: undefined,
  discountType: undefined,
  discountValue: undefined,
  shippingAmount: undefined,
  lines: [],
  send: false,
  attachments: [],
  newItems: [],
  additionalLines: [],
};

const BusinessContext = createContext<any>(null);

export const useBusiness = () => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error("useBusiness must be used within a BusinessContext.Provider");
  }
  return context;
};

export const SalesInvoiceForms = () => {
  const refForm = useRef<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const INV_LIST = usePermission("INV_LIST");

  const { hasAccess: branchHasAccess } = usePermission("INV_RES");
  const [load, setLoad] = useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading } = useSwr<IFormData | any>(
    params.id && params.id !== "new" && INV_LIST.isView ? `/api/invoice/${params.id}` : null,
    params.id && params.id !== "new" && INV_LIST.isView ? async () => await InvoiceApi.get(`${params.id}`) : null,
    {
      fallback: initData,
    },
  );

  const breadcrumbs = useBreadcrumb();

  const { data: businessList, isLoading: isLoading2 } = useSwr(
    user?.currentBusiness.id && branchHasAccess ? `/api/branch?businessId=${user.currentBusiness.id}` : null,
    user?.currentBusiness.id && branchHasAccess ? async () => await branchApi.select({ businessId: user.currentBusiness.id, query: "" }) : null,
    {
      fallback: [],
    },
  );

  const [business, setBusiness] = useState<any>(null);

  const getBusiness = async (id: string) => {
    let res = null;
    try {
      if (id) res = await NetworkApi.get(id);
      setBusiness(res);
    } catch (error) {
      console.log(error);
    }
  };

  const isNew = params.id === "new";

  const onSubmit = async (values: IFormData | any, send: boolean) => {
    setLoad(true);
    try {
      if (isNew) {
        await InvoiceApi.create({
          senderFinUserId: user?.id,
          senderBranchId: values.senderBranchId,
          senderAccId: values.senderAccId,
          receiverBusinessId: values.receiverBusinessId,
          paymentDate: values.paymentDate,
          receiverBranchId: values.receiverBranchId,
          description: values?.description || " ",
          discountType: values.discountType,
          discountValue: values.discountValue ? parseFloat(values.discountValue) : null,
          shippingAmount: values.shippingAmount ? parseFloat(values.shippingAmount) : null,
          attachments: (values?.attachments || [])?.map((item: IFileItem) => {
            return {
              description: item.description,
              name: item.name,
              url: item.url,
            };
          }),
          lines: values?.lines
            ?.filter((item: any) => !item.isEdit && (!item?.additionalLines || false))
            ?.map((item: IItem) => {
              return {
                variantId: item?.variantId || item?.id,
                discountType: item?.discountType,
                discountValue: item?.discountValue,
                quantity: item?.quantity || 0,
              };
            }),
          additionalLines: (values.additionalLines ?? []).map((item: IItem | any) => {
            return {
              quantity: item?.quantity || 0,
              image: item?.image,
              name: item?.name,
              unit: item?.unit,
              discountType: item?.discountType,
              discountValue: item?.discountValue,
              price: item?.price || 0,
            };
          }),
          send: send,
        });
      } else {
        await InvoiceApi.update(params.id || "", {
          senderFinUserId: user?.id,
          senderBranchId: values.senderBranchId,
          senderAccId: values.senderAccId,
          receiverBusinessId: values.receiverBusinessId,
          paymentDate: values.paymentDate,
          receiverBranchId: values.receiverBranchId,
          description: values.description,
          discountType: values.discountType,
          discountValue: values.discountValue ? parseFloat(values.discountValue) : null,
          shippingAmount: values.shippingAmount ? parseFloat(values.shippingAmount) : null,
          attachments: (values?.attachments || [])?.map((item: IFileItem) => {
            return {
              description: item?.description,
              name: item?.name,
              url: item.url,
            };
          }),
          lines:
            values?.lines
              ?.filter((item: any) => !item.isEdit)
              ?.map((item: IItem) => {
                return {
                  variantId: item?.variantId || item?.id,
                  discountType: item.discountType,
                  discountValue: item.discountValue,
                  quantity: item.quantity || 0,
                };
              }) || [],
          additionalLines:
            values?.additionalLines?.map((item: IItem | any) => {
              return {
                image: item?.image,
                name: item?.name,
                unit: item?.unit,
                price: item?.price,
                discountType: item?.discountType,
                discountValue: item?.discountValue || null,
                quantity: item?.quantity,
              };
            }) || [],
          send: send,
        });
      }

      if (send) message.success("Нэхэмжлэл амжилттай бүртгэгдлээ.");
      else message.success("Нэхэмжлэл амжилттай бүртгэгдлээ.");
      navigate("/sales-invoice");
    } catch (error: any) {
      message.error(error.message || "");
      setLoad(false);
    }
    setLoad(false);
  };

  if ((isLoading && params.id !== "new") || isLoading2) {
    return <LoadingOverlay visible />;
  } else
    return (
      <BusinessContext.Provider value={{ business, isNew, getBusiness }}>
        <Form
          ref={refForm}
          validationSchema={schema}
          onSubmit={(e) => {
            return e;
          }}
          initialValues={{ ...initData, ...data, senderBranchId: data?.senderBranchId || businessList?.find((item: any) => item.isDefault)?.id }}>
          {({ values, setFieldValue, errors }) => {
            return (
              <PageLayout
                title={params.id === "new" ? "Шинэ нэхэмжлэх" : "Нэхэмжлэх засах"}
                subTitle={params.id === "new" ? "Борлуулалтын шинэ нэхэмжлэх үүсгэх" : "Борлуулалтын шинэ нэхэмжлэх засах"}
                breadcrumb={breadcrumbs.map((item: any, index: number) => {
                  if (params.id !== "new" && breadcrumbs.length - 1 === index) return { ...item, label: "Нэхэмжлэх засах" };
                  return item;
                })}
                extra={[
                  <Group key={12} hidden={!INV_LIST.isCreate || !INV_LIST.isEdit}>
                    <Button
                      hidden={!INV_LIST.isEdit}
                      type="submit"
                      loading={load}
                      onClick={async () => {
                        let values = await refForm.current.submit();
                        if (!!values) onSubmit(values, false);
                      }}
                      variant="outline"
                      key={0}>
                      Хадгалах
                    </Button>
                    <Button
                      hidden={!INV_LIST.isCreate}
                      loading={load}
                      type="submit"
                      onClick={async () => {
                        let values = await refForm.current.submit();
                        if (!!values) onSubmit(values, true);
                      }}
                      key={1}>
                      Батлаад илгээх
                    </Button>
                  </Group>,
                ]}>
                {INV_LIST.isCreate ? (
                  <Paper withBorder>
                    {isLoading ? (
                      <LoadingOverlay visible />
                    ) : (
                      <Accordion defaultValue={["1", "2", "3", "4"]} multiple>
                        {option({ values: { ...values }, setFieldValue, errors }).map((item: any, index: number) => {
                          return (
                            <Accordion.Item key={index} value={`${index + 1}`}>
                              <Accordion.Control style={{ borderBottom: "1px solid #DEE2E6", borderStyle: "dashed" }}>
                                <div>
                                  <Text fw={500}>{item.title}</Text>
                                  <Text c="gray" fz={"xs"}>
                                    {item.subTitle}
                                  </Text>
                                </div>
                              </Accordion.Control>

                              <Accordion.Panel>
                                <Paper py={"xs"}>{item.children}</Paper>
                              </Accordion.Panel>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    )}
                  </Paper>
                ) : (
                  INV_LIST.accessDenied()
                )}
              </PageLayout>
            );
          }}
        </Form>
      </BusinessContext.Provider>
    );
};

const option = ({ values, setFieldValue, errors }: { errors: any; values: any; setFieldValue: any }) => [
  {
    title: "Худалдан авагч",
    subTitle: "Нэхэмжлэхийн төлбөр төлөгч байгууллагын мэдээлэл",
    children: <InvoiceReceiverForm values={values} setFieldValue={setFieldValue} />,
  },
  {
    title: "Нэхэмжлэх илгээж буй тал",
    subTitle: "Нэхэмжлэх илгээж буй партнер байгууллагын мэдээлэл",
    children: <InvoiceSenderForm values={values} setFieldValue={setFieldValue} />,
  },
  {
    title: "Нэхэмжлэхийн зүйл",
    subTitle: "Нэхэмжлэхийн зүйл, дэлгэрэнгүй мэдээлэл",
    children: <InvoiceProductForm values={values} setFieldValue={setFieldValue} errors={errors} />,
  },
  {
    title: "Хавсралт файлууд",
    subTitle: "Хавсаргасан файлын жагсаалт, дэлгэрэнгүй мэдээлэл",
    children: <InvoiceAttachmentForm values={values} setFieldValue={setFieldValue} />,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/sales-invoice",
    label: "Манай авах",
  },
  {
    label: "Шинэ нэхэмжлэх",
  },
];
