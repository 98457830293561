import { Button, Grid, Group, Input, Paper, Space, Text } from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router";
import { dateTimeFormat } from "../../utils/date";
import { SalesInvoicesList } from "./sales-invoices-list";

export const ClosedInvoice = ({ data }: { data: any }) => {
  const params = useParams();
  const [dateRange, setDateRange] = useState<[DateValue, DateValue]>([null, null]);
  const [filters, setFilters] = useState<any>({
    businessId: `${params.id}`,
    type: "CLOSED",
    startDate: null,
    endDate: null,
  });

  return (
    <>
      <Paper my={"xs"}>
        <Group align="apart">
          <Text fw={700} fz={"xl"}>
            Buyer-ийн нэр:{" "}
            <Text c="brand" component="span">
              {data?.receiverBusiness?.profileName || "-"}
            </Text>
            <Text c="brand"># {data?.receiverBusiness?.refCode || "-"}</Text>
          </Text>

          <Text component="span" ta="end" fz={"sm"} fw={500}>
            <Text>Огноо, цаг:</Text>
            <Text>{dateTimeFormat(data.createdAt)} </Text>
            <Text> (Current)</Text>
          </Text>
        </Group>
        <Space h={"lg"} />
        <Grid>
          <Grid.Col>
            <Paper withBorder p={"lg"}>
              <Text c="gray" fw={500} mb={"xs"}>
                Нэхэмжлэх түүх харах
              </Text>
              <Input.Wrapper label="Эхлэх / Дуусах огноо сонгох">
                <Group w={"100%"}>
                  <DatePickerInput
                    type="range"
                    clearable
                    value={dateRange}
                    onChange={setDateRange}
                    maw={"500px"}
                    w={"calc(100% - 150px)"}
                    placeholder="Эхлэх / Дуусах огноо сонгох"
                    valueFormat="YYYY-MM-DD"
                    leftSection={<IconCalendar size={"1.2rem"} />}
                  />
                  <Button
                    onClick={() => {
                      if (dateRange[0] && dateRange[1])
                        setFilters({ ...filters, startDate: dayjs(dateRange[0]).format(), endDate: dayjs(dateRange[1]).format() });
                      else {
                        setFilters({ ...filters, startDate: null, endDate: null });
                      }
                    }}
                    w={"132px"}>
                    Түүх харах
                  </Button>
                </Group>
              </Input.Wrapper>
            </Paper>
          </Grid.Col>
          <Grid.Col>
            <SalesInvoicesList title="Хаагдсан нэхэмжлэхүүд" filters={filters} />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
};
