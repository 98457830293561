import { ActionIcon, Collapse, Divider, Grid, Group, Input, Select } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconFilter, IconListSearch } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { NetworkSettlementApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { IAuth } from "../../interfaces/IAuth";
import { CustormerBillingBuyer } from "./buyer";
import { CustormerBillingSupplier } from "./supplier";

export const CustormerBilling = () => {
  const breadcrumbs = useBreadcrumb();
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const [hasFilter, setHasFilter] = useState<boolean>(true);
  const INV_NET_SETT = usePermission("INV_NET_SETT");
  const [filters, setFilters] = useState<{ regNumber: string | null; businessId: string | null; partnerId: string | null }>({
    regNumber: null,
    businessId: null,
    partnerId: null,
  });

  const [partnerQuery, setPartnerQuery] = useState<string>("");
  const [partnerFilterDelay] = useDebouncedValue<string>(partnerQuery, 800);

  const { data: partnerSelects = [] } = useSwr<any[]>(
    `/erp/goods/partner/select?query=${JSON.stringify(partnerFilterDelay)}`,
    async () =>
      await NetworkSettlementApi.partner_select({
        query: partnerFilterDelay ?? "",
      }),
    {
      fallback: [],
    },
  );

  const [businessQuery, setBusinessQuery] = useState<string>("");
  const [businessFilterDelay] = useDebouncedValue<string>(businessQuery, 800);

  const { data: businessSelects = [] } = useSwr<any[]>(
    filters.partnerId ? `/erp/goods/business/select?query=${JSON.stringify(businessFilterDelay)}&&partnerId=${JSON.stringify(filters?.partnerId)}` : null,
    async () =>
      filters.partnerId
        ? await NetworkSettlementApi.business_select({
            query: businessFilterDelay ?? "",
            partnerId: filters.partnerId,
          })
        : null,
    {
      fallback: [],
    },
  );

  return (
    <PageLayout
      title="Харилцагчийн тооцоо"
      subTitle="Харилцагчийн тооцооны дэлгэрэнгүй "
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <ActionIcon onClick={() => setHasFilter(!hasFilter)} variant={hasFilter ? "light" : "filled"} size={36} color="brand">
            <IconFilter />
          </ActionIcon>
        </Group>,
      ]}>
      <Divider />
      {INV_NET_SETT.hasAccess ? (
        <div>
          <Collapse in={hasFilter}>
            <Grid my={"md"}>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <Input.Wrapper label="Татвар төлөгчийн дугаараар">
                  <Input
                    value={`${filters?.regNumber || ""}`}
                    onChange={(e) => {
                      setFilters({ ...filters, regNumber: e.currentTarget.value });
                    }}
                    placeholder="Татвар төлөгчийн дугаараар"
                    rightSection={<IconListSearch color="gray" size={"1.2rem"} />}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <Input.Wrapper label="Партнерийн нэрээр хайх">
                  <Select
                    searchValue={partnerQuery}
                    searchable
                    data={(partnerSelects ?? []).map((option) => {
                      return {
                        label: option?.businessName ?? "",
                        value: option?.id ?? "",
                      };
                    })}
                    onSearchChange={(e) => {
                      setPartnerQuery(e);
                    }}
                    value={`${filters?.partnerId ?? ""}`}
                    onChange={(e: string | null) => {
                      setFilters({
                        ...filters,
                        partnerId: e,
                        businessId: "",
                      });
                    }}
                    placeholder="Партнерийн нэрээр хайх"
                    rightSection={<IconListSearch color="gray" size={"1.2rem"} />}
                    clearable
                    nothingFoundMessage="Хайлт олдсонгүй"
                    maxDropdownHeight={400}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <Input.Wrapper label="Харилцагчийн нэрээр хайх">
                  <Select
                    disabled={!filters?.partnerId}
                    searchValue={businessQuery}
                    searchable
                    data={(businessSelects ?? []).map((option) => {
                      console.log("businessSelects", option);
                      return {
                        label: option?.profileName ?? "",
                        value: option.id ?? "",
                      };
                    })}
                    onSearchChange={(e) => {
                      setBusinessQuery(e);
                    }}
                    value={`${filters?.businessId ?? ""}`}
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        businessId: e,
                      });
                    }}
                    placeholder="Харилцагчийн нэрээр хайх"
                    rightSection={<IconListSearch color="gray" size={"1.2rem"} />}
                    clearable
                    maxDropdownHeight={400}
                    nothingFoundMessage="Хайлт олдсонгүй"
                  />
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </Collapse>
          {myBusinessType === "BUYER" && <CustormerBillingBuyer filters={hasFilter ? filters : filterInit} />}
          {myBusinessType === "SUPPLIER" && <CustormerBillingSupplier filters={hasFilter ? filters : filterInit} />}
        </div>
      ) : (
        INV_NET_SETT.accessDenied()
      )}
    </PageLayout>
  );
};

const filterInit = {
  regNumber: "",
  businessId: "",
  partnerId: "",
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нэхэмжлэх удирдлага",
  },
  {
    label: "Харилцагчийн тооцоо",
  },
];
