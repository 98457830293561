import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const variant_select = async (data: { query: string | null | any; businessId?: string | any; categoryId?: string | any; brandId?: string | any }) =>
  httpRequest.get("/erp/invoice/variant/select", data);

export const variant_list = async (data: {
  filter: {
    businessId: null;
    query: string | null;
    supplierId: string | null;
    categoryId: string | null;
    brandId: string | null;
  };
  offset: {
    page: number;
    limit: number;
  };
}) => httpRequest.get("/erp/invoice/variant", data);

export const brandSelect = async (data: { businessId?: string | null; query: string | null }) => httpRequest.get("/erp/invoice/brand_select", data);
export const categorySelect = async (data: { businessId?: string | null; query: string | null }) => httpRequest.get("/erp/invoice/category_select", data);
