import { Avatar, Box, Checkbox, Divider, Flex, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

export function InvoiceProductDetailsForm({ values }: any) {
  const { vatTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [edit, setEdit] = useState<string>("");

  const columns = useHeader({ vatTypes, edit, setEdit });
  const columns2 = useHeader2({ vatTypes, edit, setEdit });

  return (
    <Paper>
      <Flex direction="column" gap={14}>
        <Table columns={columns} name="values?.lines2" dataSource={values?.lines ?? []} />
        <Text fz={"lg"} fw={600}>
          Нэмэлтээр
        </Text>
        <Table columns={columns2} name="values?.additionalLines1" dataSource={values?.additionalLines ?? []} />
        <Divider my="md" />
        <Grid>
          <Grid.Col span={6}>
            <Grid>
              <Grid.Col span={12}>
                <Input.Wrapper label="Тайлбар:">
                  <Paper>
                    <Text size="sm" c="dimmed" maw={"600px"}>
                      {values.description ?? "-"}
                    </Text>
                  </Paper>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={6}>
            <Paper p="md" withBorder>
              <Flex align="flex-end" direction="column" gap={10}>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Нийт барааны дүн:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>{currencyFormat(values?.itemsTotal ?? 0)}</Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Тооцсон НӨАТ:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>{currencyFormat(values?.vatAmount ?? 0)}</Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Тооцсон НХАТ:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>{currencyFormat(values?.taxAmount ?? 0)}</Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Нэмэлт:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>
                    {currencyFormat(values?.additionalLines?.reduce((total: number, item: any) => total + (item?.quantity ?? 0) * (item?.price ?? 0), 0) ?? 0)}
                  </Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Нийт дүнгийн хөнгөлөлт:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>{currencyFormat(values?.discountAmount ?? 0)}</Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text style={{ width: 300, textAlign: "right" }}>Хүргэлтийн төлбөр:</Text>
                  <Text style={{ width: 150, textAlign: "right" }}>{currencyFormat(values?.shippingAmount ?? 0)}</Text>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
                  <Text fw={500} c="indigo" style={{ width: 300, textAlign: "right" }}>
                    НИЙТ ТӨЛБӨР:
                  </Text>
                  <Text fw={500} c="indigo" style={{ width: 150, textAlign: "right" }}>
                    {currencyFormat(values?.totalAmount ?? 0)}
                  </Text>
                </Box>
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
      </Flex>
    </Paper>
  );
}

const useHeader = ({ vatTypes }: { vatTypes: IReference[]; edit: any; setEdit: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    width: 140,
    render: (record) => (
      <Avatar src={`${record?.image ?? ""}`}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    width: 140,
    render: (record) => `${record?.skuCode ?? "-"}`,
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    sorter: true,
    dataIndex: "nameMon",
    width: 140,
    render: (record) => `${record?.name ?? "-"}`,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit.name",
    width: 140,
    render: (record) => `${record?.unit ?? "-"}`,
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    width: 140,
    render: (record) => (
      <Group align="right">
        <Text w={"max-content"}>{currencyFormat(record?.price ?? 0)}</Text>
      </Group>
    ),
  },
  {
    title: "НӨАТ",
    sorter: true,
    dataIndex: "hasVat",
    width: 140,
    render: (record) => (
      <Group align="right">
        <Text w={"max-content"}>{currencyFormat(record?.vatAmount ?? 0)}</Text>
      </Group>
    ),
  },
  {
    title: "НХАТ",
    sorter: true,
    dataIndex: "hasTax",
    width: 140,
    render: (record) => <Checkbox disabled checked={record?.hasTax} onChange={() => {}} />,
  },
  {
    title: "Тоо хэмжээ",
    sorter: true,
    dataIndex: "quantity",
    render: (record, index) => {
      return (
        <Group align="center">
          <Text w={150}>{record.quantity ?? "0"}</Text>
        </Group>
      );
    },
  },
  {
    title: "Үнийн дүн",
    sorter: true,
    dataIndex: "name",
    width: 140,
    render: (record: any) => {
      let count;
      count = ((record.vatAmount ?? 0) + (record?.price ?? 0)) * (record?.quantity ?? 0);
      return (
        <Group align="right">
          <Text w={"max-content"}>{currencyFormat(count ?? 0)}</Text>
        </Group>
      );
    },
  },
];

const useHeader2 = ({ vatTypes }: { vatTypes: IReference[]; edit: any; setEdit: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    sorter: true,
    dataIndex: "nameMon",
    width: 140,
    render: (record) => `${record?.name ?? "-"}`,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit.name",
    width: 140,
    render: (record) => `${record?.unit ?? "-"}`,
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    width: 140,
    render: (record) => (
      <Group align="right">
        <Text w={"max-content"}>{currencyFormat(record?.price ?? 0)}</Text>
      </Group>
    ),
  },
  {
    title: "Тоо хэмжээ",
    sorter: true,
    dataIndex: "quantity",
    render: (record, index) => {
      return (
        <Group align="center">
          <Text w={150}>{record.quantity ?? "0"}</Text>
        </Group>
      );
    },
  },
  {
    title: "Үнийн дүн",
    sorter: true,
    dataIndex: "name",
    width: 140,
    render: (record: any) => {
      let count;
      count = ((record.vatAmount ?? 0) + (record?.price ?? 0)) * (record?.quantity ?? 0);
      return <Group align="right">{currencyFormat(count ?? 0)}</Group>;
    },
  },
];
