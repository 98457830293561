import { ActionIcon, Avatar, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { NetworkSettlementApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const BillingHistory = () => {
  const { paymentMethod } = useSelector((state: { general: IGeneral }) => state.general);
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const INV_NET_SETT = usePermission("INV_NET_SETT");
  const params = useParams();
  const [filters] = useState<any>({ invoiceId: params.id! });
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const location = useLocation();
  const { state = {} } = location;

  const columns = useHeader({
    onClick: (key, record) => {},
    paymentMethod,
    state,
  });

  return (
    <PageLayout
      title={
        <Flex gap="xs">
          <span>
            <ActionIcon onClick={() => navigate(-1)} variant="outline" size="sm">
              <IconArrowLeft />
            </ActionIcon>
          </span>
          <Text>Төлбөрийн түүх нэхэмжлэхээр:</Text>
          <Text c="brand" component="span">
            {state?.refCode}#
          </Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}>
      <Divider h={"md"} />
      {INV_NET_SETT.hasAccess ? (
        <div>
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Input.Wrapper label="Партнер нэр">
                {state?.type === "SALES" ? (
                  <div>
                    {myBusinessType === "SUPPLIER" ? (
                      <div>
                        {state?.receiverBusiness ? (
                          <Text size="sm">
                            <Text c="brand" component="span">
                              # {state?.receiverBusiness?.partner?.refCode ?? "-"},
                            </Text>{" "}
                            {state?.receiverBusiness?.partner?.businessName ?? "-"}
                          </Text>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {state?.senderBusiness ? (
                          <Text size="sm">
                            <Text c="brand" component="span">
                              # {state?.senderBusiness?.partner?.refCode ?? "-"},
                            </Text>{" "}
                            {state?.senderBusiness?.partner?.businessName ?? "-"}
                          </Text>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {myBusinessType === "BUYER" ? (
                      <div>
                        {state?.receiverBusiness ? (
                          <Text size="sm">
                            <Text c="brand" component="span">
                              # {state?.receiverBusiness?.partner?.refCode ?? "-"},
                            </Text>{" "}
                            {state?.receiverBusiness?.partner?.businessName ?? "-"}
                          </Text>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {state?.senderBusiness ? (
                          <Text size="sm">
                            <Text c="brand" component="span">
                              # {state?.senderBusiness?.partner?.refCode ?? "-"},
                            </Text>{" "}
                            {state?.senderBusiness?.partner?.businessName ?? "-"}
                          </Text>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Input.Wrapper label={myBusinessType === "SUPPLIER" ? "Худалдан авагч нэр" : "Нийлүүлэгч бизнес"}>
                <div>
                  {state.type === "SALES" ? (
                    <>
                      {myBusinessType === "SUPPLIER" ? (
                        <>
                          {state?.receiverBusiness ? (
                            <Text size="sm">
                              <Text c="brand" component="span">
                                # {state?.receiverBusiness?.refCode ?? "-"},
                              </Text>{" "}
                              {state?.receiverBusiness?.profileName ?? "-"}
                            </Text>
                          ) : (
                            <div>-</div>
                          )}
                        </>
                      ) : (
                        <>
                          {state?.senderBusiness ? (
                            <Text size="sm">
                              <Text c="brand" component="span">
                                # {state?.senderBusiness?.refCode ?? "-"},
                              </Text>{" "}
                              {state?.senderBusiness?.profileName ?? "-"}
                            </Text>
                          ) : (
                            <div>-</div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {myBusinessType === "BUYER" ? (
                        <>
                          {state?.receiverBusiness ? (
                            <Text size="sm">
                              <Text c="brand" component="span">
                                # {state?.receiverBusiness?.refCode ?? "-"},
                              </Text>{" "}
                              {state?.receiverBusiness?.profileName ?? "-"}
                            </Text>
                          ) : (
                            <div>-</div>
                          )}
                        </>
                      ) : (
                        <>
                          {state?.senderBusiness ? (
                            <Text size="sm">
                              <Text c="brand" component="span">
                                # {state?.senderBusiness?.refCode ?? "-"},
                              </Text>{" "}
                              {state?.senderBusiness?.profileName ?? "-"}
                            </Text>
                          ) : (
                            <div>-</div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Input.Wrapper label="Баталсан">
                <Text size="sm">
                  <Text c="brand" component="span">
                    {currencyFormat(state?.confirmedAmount ?? 0)}
                  </Text>{" "}
                  {state?.confirmedDate ? ", " + dateTimeFormat(`${state?.confirmedDate ?? ""}`) : ""}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Input.Wrapper label="Үлдэгдэл дүн">
                <Text c="brand" size="sm">
                  {currencyFormat(state?.amountToPay ?? 0)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Divider my={"lg"} />
          {params?.id && <Table name="requests.list.history" columns={columns} filters={filters} loadData={(data) => NetworkSettlementApi.history(data!)} />}
        </div>
      ) : (
        INV_NET_SETT.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  paymentMethod,
  state,
}: {
  onClick: (key: string, record: any) => void;
  paymentMethod: IReference[];
  state: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Гүйлгээний огноо, цаг",
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Гүйлгээний лавлах",
    render: (record) => {
      return <Text size="sm">{record?.trxRefCode ?? "-"}</Text>;
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => {
      return (
        <Text c="brand" size="sm">
          #{state?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн дүн",
    render: (record) => (
      <Text size="sm" ta="end">
        {currencyFormat(record?.trxAmount ?? 0)}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {paymentMethod.find((i: IReference) => i.code === record.paymentMethod)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Хүлээн авагч данс",
    render: (record) =>
      record?.debitAcc ? (
        <Text w={"max-content"} size="sm">
          {record?.debitAcc?.name ?? "-"}, {record?.debitAcc?.number ?? "-"}, {record?.debitAcc?.bankName ?? "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Шилжүүлэгч данс",
    render: (record) =>
      record?.creditAcc ? (
        <Text w={"max-content"} size="sm">
          {record?.creditAcc?.name ?? "-"}, {record?.creditAcc?.number ?? "-"}, {record?.creditAcc?.bankName ?? "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Төлбөр хийсэн",
    render: (record) =>
      record.actionUser ? (
        <Flex align="center" gap="10">
          <Avatar size={32} src={record.actionUser?.avatar} />
          <Text size="sm">
            {record.actionUser?.lastName ? record.actionUser?.lastName[0] + "." : ""}
            {record?.actionUser?.firstName ?? "-"}{" "}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нэхэмжлэх удирдлага",
  },
  {
    label: "Харилцагчийн тооцоо",
  },
  {
    label: "Дэлгэрэнгүй",
  },
  {
    label: "Төлбөрийн түүх",
  },
];
