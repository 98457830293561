import { Grid, Group, Paper, Space, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { IAuth } from "../../interfaces/IAuth";
import { dateTimeFormat } from "../../utils/date";
import { SalesInvoicesList } from "./sales-invoices-list";

export const OpenedInvoice = ({ data }: { data: any }) => {
  const params = useParams();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  return (
    <Paper my={"xs"}>
      <Group align="apart">
        <Text fw={700} fz={"xl"}>
          Buyer-ийн нэр:{" "}
          <Text c="brand" component="span">
            {user?.currentBusiness.type === "SUPPLIER" ? data?.receiverBusiness?.profileName || "-" : data?.senderBusiness?.profileName || "-"}
          </Text>
          <Text c="brand">#{user?.currentBusiness.type === "SUPPLIER" ? data?.receiverBusiness?.refCode || "-" : data?.senderBusiness?.refCode || "-"}</Text>
        </Text>

        <Text component="span" ta="end" fz={"sm"} fw={500}>
          <Text>Огноо, цаг:</Text>
          <Text>{dateTimeFormat(data.createdAt)} </Text>
          <Text> (Current)</Text>
        </Text>
      </Group>
      <Space h={"xl"} />
      <Grid>
        <Grid.Col>
          <SalesInvoicesList
            title="Төлбөрийн үлдэгдэлтэй нэхэмжлэхүүд"
            filters={{
              businessId: `${params.id}`,
              type: "CONFIRMED",
            }}
          />
        </Grid.Col>
        <Grid.Col>
          <SalesInvoicesList
            title="Батлагдах хүлээж буй нэхэмжлэх"
            filters={{
              businessId: `${params.id}`,
              type: "PENDING",
            }}
          />
        </Grid.Col>
        <Grid.Col>
          <SalesInvoicesList
            title="Санхүүжилт авсан нэхэмжлэх"
            filters={{
              businessId: `${params.id}`,
              type: "FINANCED",
            }}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
