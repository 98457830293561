import { Box, Paper, Switch, Table, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";

export function Test() {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions } = useSelector((state: { general: IGeneral }) => state.general);

  const rows = user?.permissions?.map((element, index) => (
    <tr key={index}>
      <td>{element.module}</td>
      <td>{permissions.find((f: any) => f.code === element?.code)?.name}</td>
      <td>{element.menu}</td>
      <td>{element.portal}</td>
      <td>{element.code}</td>
      <td>
        {element.isView !== null ? (
          <Switch
            color="green"
            thumbIcon={!element.isView ? <IconX color="gray" size={13} /> : <IconCheck size={13} color={"green"} />}
            readOnly
            checked={element.isView}
          />
        ) : (
          <Text c="gray">-</Text>
        )}
      </td>
      <td>
        {element.isCreate !== null ? (
          <Switch
            color="green"
            thumbIcon={!element.isCreate ? <IconX color="gray" size={13} /> : <IconCheck size={13} color={"green"} />}
            readOnly
            checked={element.isCreate}
          />
        ) : (
          <Text c="gray">-</Text>
        )}
      </td>
      <td>
        {element.isReview !== null ? (
          <Switch
            color="green"
            thumbIcon={!element.isReview ? <IconX color="gray" size={13} /> : <IconCheck size={13} color={"green"} />}
            readOnly
            checked={element.isReview}
          />
        ) : (
          <Text c="gray">-</Text>
        )}
      </td>
      <td>
        {element.isDelete !== null ? (
          <Switch
            color="green"
            thumbIcon={!element.isDelete ? <IconX color="gray" size={13} /> : <IconCheck size={13} color={"green"} />}
            readOnly
            checked={element.isDelete}
          />
        ) : (
          <Text c="gray">-</Text>
        )}
      </td>
    </tr>
  ));

  return (
    <Paper p={"lg"} style={{ overflow: "auto" }}>
      <Box w={"max-content"}>
        <Table>
          <thead>
            <tr>
              <th>module</th>
              <th>menu.name</th>
              <th>menu.code</th>
              <th>portal</th>
              <th>code</th>
              <th>isView</th>
              <th>isCreate</th>
              <th>isReview</th>
              <th>isDelete</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Box>
    </Paper>
  );
}
