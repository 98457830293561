import { NumberInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  rightSection?: any;
  rightSectionWidth?: any;
  icon?: any;
  max?: number;
  min?: number;
  required?: boolean;
  w?: any;
  size?: any;
};

export function NumberField({
  name,
  label,
  noError = false,
  placeholder,
  disabled = false,
  rightSection,
  rightSectionWidth,
  icon,
  max,
  min,
  required,
  w,
  size,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <NumberInput
      w={w}
      size={size}
      required={required}
      max={max}
      min={min}
      label={label}
      leftSection={icon}
      rightSectionWidth={rightSectionWidth}
      rightSection={rightSection}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value || undefined}
      onChange={(v) => onChange(v)}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: error && noError && 0,
        },
      }}
    />
  );
}
