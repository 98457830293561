import { Box, Checkbox, Group, NumberInput, Text } from "@mantine/core";
import { IconCurrencyTugrik } from "@tabler/icons-react";
import { useEffect } from "react";
import { InvoiceApi } from "../../apis";
import { IItem } from "../../interfaces/IItem";
import { currencyFormat } from "../../utils";
import { dateFormat, dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

export function RegisterTable({
  values,
  setFieldValue,
  filters,
  selected,
  setSelected,
}: {
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
  filters: any;
  selected: any;
  setSelected: any;
}) {
  const columns = useHeader({
    values,
    setFieldValue,
    selected,
    setSelected,
    onClick: (key, record) => {
      if (key === "add") {
        setSelected([
          ...selected,
          {
            amount: 0,
            invoiceId: record.id,
            refCode: record?.refCode,
          },
        ]);
      } else if (key === "remove") {
        setSelected(selected.filter((item: any) => item.invoiceId !== record.id));
      } else if (key === "update") {
        setSelected(selected.map((item: any) => (item.invoiceId === record.id ? { ...item, amount: record.amount } : item)));
      }
    },
  });

  useEffect(() => {
    setFieldValue("invoices", selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div>
      <Table name={`invoices.history.list_${filters}`} columns={columns} filters={filters} loadData={(data) => InvoiceApi.invoice_list(data!)} />
    </div>
  );
}

const useHeader = ({
  onClick,
  values,
  setFieldValue,
  selected,
  setSelected,
}: {
  onClick: (key: string, record: IItem | any) => void;
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
  selected: any;
  setSelected: any;
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Сонгох",
    align: "center",
    width: "120px",
    render: (record, index) => {
      return (
        <div>
          <Checkbox
            color="brand"
            checked={selected.some((item: any) => item?.invoiceId === record.id)}
            value={record.id}
            onChange={(e) => {
              if (selected.some((item: any) => item?.invoiceId === record.id)) onClick("remove", record);
              else onClick("add", record);
            }}
          />
        </div>
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    dataIndex: "refCode",
    render: (record) => {
      return record?.refCode === null ? (
        "-"
      ) : (
        <Text w={"max-content"}>
          <Text c="yellow.8">#{record?.refCode}</Text>
          <Text size={"xs"} c="gray">
            {dateFormat(record.createdAt)}
          </Text>
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч",
    dataIndex: "category",
    render: (record) => (
      <Text w={"max-content"}>
        <Text c="gray" size="xs">
          {record?.receiverBusiness?.partner?.businessName || "-"}
        </Text>
        <Text size="sm" c="yellow.8" fw={500}>
          #{record?.receiverBusiness?.partner?.refCode || "-"}
        </Text>
      </Text>
    ),
  },
  {
    title: "Бизнесийн нэр",
    dataIndex: "itemStatus",
    render: (record) => (
      <Text w={"max-content"}>
        <Text color="gray" size="xs" fw={500}>
          {record?.receiverBusiness?.profileName || "-"}
        </Text>
        <Text size="sm" fw={500} c="yellow.8">
          #{record?.receiverBusiness?.refCode || "-"}
        </Text>
      </Text>
    ),
  },
  {
    title: "Үлдэгдэл төлбөр",
    dataIndex: "amountToPay",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end">
        {currencyFormat(record?.amountToPay || 0)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    dataIndex: "paymentDate",
    render: (record) => `${record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}`,
  },
  {
    title: "Үлдсэн хугацаа",
    dataIndex: "remainingDays",
    render: (record) => `${record?.remainingDays} хоног`,
  },
  {
    title: "Бүртгэх төлбөр",
    dataIndex: "selected",
    render: (record) => (
      <Box w={"200px"}>
        {selected.some((item: any) => item.invoiceId === record.id) ? (
          <NumberInput
            min={0}
            disabled={!selected.some((item: any) => item.invoiceId === record.id)}
            rightSection
            leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
            placeholder="0.00"
            error={!selected.find((item: any) => item.invoiceId === record.id)?.amount}
            value={selected.find((item: any) => item.invoiceId === record.id)?.amount || undefined}
            onChange={(e: any) => {
              onClick("update", { ...record, amount: e });
            }}
          />
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    title: "Үлдэх тооцоо",
    dataIndex: "amountToPay",
    render: (record) => (
      <Group align="right">
        <Text
          w={"max-content"}
          fw={500}
          c={
            (record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0) === 0
              ? "green"
              : (record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0) > 0
              ? "black"
              : "red"
          }>
          {currencyFormat((record?.amountToPay || 0) - (selected.find((item: any) => item?.invoiceId === record?.id)?.amount || 0))}
        </Text>
      </Group>
    ),
  },
  {
    title: "Илгээсэн огноо",
    dataIndex: "sentDate",
    render: (record) => (
      <Text c="dimmed" w={"max-content"}>
        {record?.sentDate ? dateTimeFormat(record?.sentDate) : "-"}
      </Text>
    ),
  },
];
