import { Anchor, AspectRatio, Avatar, Button, CloseButton, Divider, Flex, Grid, Group, Image, Input, Modal, Overlay, Paper, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconEye, IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import * as yup from "yup";
import { currencyFormat } from "../../utils";
import { Form } from "../form";
import { NumberField } from "../form/number-field";
import { PageLayout } from "../layout";

const schema = yup.object({
  variantId: yup.string().required("Заавал бөглөнө!").nullable(),
  quantity: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

export function InvoiceProductItemEditForm({ action, setFieldValue, setAction, values: formValues }: any) {
  const [image, setImage] = useState<any[]>([]);
  const { hovered, ref } = useHover();
  const [data] = useState<any>({
    variantId: (action[1] && action[1]?.id) || action[1]?.variantId,
    refCode: undefined,
    skuCode: undefined,
    barCode: undefined,
    erpCode: undefined,
    nameMon: undefined,
    nameEng: undefined,
    nameBill: undefined,
    nameWeb: undefined,
    nameApp: undefined,
    image: undefined,
    category: undefined,
    supplier: undefined,
    brand: undefined,
    priceStartDate: undefined,
    hasVat: false,
    vatAmount: undefined,
    hasTax: false,
    taxAmount: undefined,
    price: undefined,
    quantity: undefined,
    index: undefined,
    ...(action[0] === "edit" && action[1]),
    unit: action[1]?.unit?.name || undefined,
  });

  const onSubmit = async (values: any) => {
    // if (action[0] === "add") setFieldValue("lines", [...(action[1]?.lines || []), values]);

    setFieldValue("lines", [
      ...formValues?.lines?.filter((item: any) => item.variantId !== values.variantId),
      {
        ...values,
      },
    ]);
    setAction([]);
  };

  return (
    <div>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <Paper>
              <PageLayout
                title="Бараа засах"
                subTitle="Бараа бүтээгдэхүүн засах"
                extra={[<CloseButton onClick={() => setAction([])} type="submit" key={1}></CloseButton>]}>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={"Зураг"}>
                      <Paper>
                        <AspectRatio pos={"relative"} ref={ref} ratio={16 / 9} maw={100} mah={100}>
                          <Anchor
                            onClick={() => {
                              if (values.image) setImage(["view", values?.image]);
                            }}>
                            <Avatar w={"100px"} h={"60px"} src={values?.image || ""}>
                              <IconPhoto />
                            </Avatar>
                            {values.image && hovered && (
                              <Overlay color="#000000" opacity={0.6}>
                                <Flex align="center" justify="center" h={"100%"}>
                                  <IconEye color="white" />
                                </Flex>
                              </Overlay>
                            )}
                          </Anchor>
                        </AspectRatio>
                      </Paper>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={"Ref Код"}>
                      <Text fz={"sm"} fw={500} c="brand">
                        #{values?.refCode || "Авто үүснэ"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={"Бараа, ажил үйлчилгээ"}>
                      <Text fz={"sm"} fw={500} c="brand">
                        {values?.nameMon || "Авто үүснэ"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={"SKU код"}>
                      <Text fz={"sm"} fw={500} c="brand">
                        {values?.skuCode || "Авто үүснэ"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={"Хэмжих нэгж"}>
                      <Text fz={"sm"} fw={500} c="brand">
                        {values?.unit || "Авто үүснэ"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={`Нэгжийн үнэ  /${values?.unit || "ширхэг"}/`}>
                      <Text fz={"sm"} fw={500} c="brand">
                        {currencyFormat(values?.price || 0)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label={`Нийт үнэ`} required>
                      <Text fz={"xl"} fw={500} c="brand">
                        {currencyFormat((values?.price || 0) * (values.quantity || 0))}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <NumberField min={0} name="quantity" placeholder="Тоо хэмжээ" label="Тоо хэмжээ" required />
                  </Grid.Col>
                </Grid>
                <Divider my="lg" />
                <Group align="right">
                  <Button type="submit">Болсон</Button>
                </Group>
              </PageLayout>
            </Paper>
          );
        }}
      </Form>

      <Modal
        centered
        title="Зураг"
        opened={image[0] === "view"}
        onClose={() => {
          setImage([]);
        }}>
        {image[1] && <Image src={image[1]} />}
      </Modal>
    </div>
  );
}
