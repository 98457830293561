import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const respond = async (
  id: string,
  data: {
    confirm: boolean;
    respondText: string;
  },
) => {
  return httpRequest.put(`/inv/invoice/${id}/respond`, data);
};

export const create = async (data: any) => {
  return httpRequest.post("/inv/invoice", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/inv/invoice/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/inv/invoice/${id}`);
};

export const received = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/inv/invoice/received", {
    offset,
    filter,
  });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/inv/invoice/${id}`);
};

export const send = async (id: string, data: any) => {
  return httpRequest.get(`/inv/invoice/${id}/send`, data);
};

export const sent = async ({ offset, filter }: IFilter) => {
  return httpRequest.get(`/inv/invoice/sent`, {
    offset,
    filter,
  });
};

export const pay = async (data: any) => {
  return httpRequest.post(`/pay/invoice`, data);
};

export const history = async ({ offset, filter }: IFilter) => {
  return httpRequest.get(`/inv/invoice/history`, {
    offset,
    filter,
  });
};

export const historyCreate = async (data: IHistoryCreate) => {
  return httpRequest.post(`/inv/invoice/history`, data);
};

export const close = async ({ offset, filter }: IFilter) => {
  return httpRequest.get(`/inv/invoice/closed`, {
    offset,
    filter,
  });
};

type IHistoryCreate = {
  receiverBusinessId: string | undefined | null;
  paymentMethod: string | undefined | null;
  totalAmount: number | undefined | null;
  trxRefCode: string | undefined | null;
  invoices:
    | any[]
    | {
        invoiceId: undefined | string;
        amount: number | undefined;
      }[];
  files: any[];
};

export const invoice_list = async (data: any) => {
  return httpRequest.get("/inv/invoice/history/invoice_list", data);
};

export const pdf = async (id: string) => {
  return httpRequest.get(`/inv/invoice/${id}/pdf`);
};
