import { Center, FileInput, FileInputProps, Group } from "@mantine/core";
import { IconFileText, IconUpload } from "@tabler/icons-react";
import React, { useState } from "react";
import { MediaApi } from "../../apis";
import { message } from "../../utils/Message";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  size?: number;
  radius?: number;

  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  nullable?: boolean;
};

export function FileUpload({ onChange, error, value, name, label, placeholder, disabled = false, nullable }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = React.useState<IFile | null>(null);
  const [test, setTest] = useState<File | null>(null);

  const onFileUpload = (blob: any) => {
    if (!blob) return;

    (async () => {
      try {
        const form = new FormData();
        form.append("file", blob);

        const res = await MediaApi.uploadFile(form);

        console.log("res", res);

        if (onChange)
          onChange({
            file: blob,
            url: res.url,
            uploading: false,
          });

        setFile({
          file: blob,
          url: res.url,
          uploading: false,
        });
      } catch (err: any) {
        setFile(null);
        message.error(err.message);
        console.log(err);
      }
    })();

    setFile({
      file: blob,
      uploading: true,
    });
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  function Value({ file }: { file: File }) {
    return (
      <Center
        inline
        sx={(theme) => ({
          backgroundColor: theme.colors.dark[7],
          fontSize: theme.fontSizes.xs,
          padding: "3px 7px",
          borderRadius: theme.radius.sm,
        })}>
        <IconFileText size={14} style={{ marginRight: 5 }} />
        <span
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: 200,
            display: "inline-block",
          }}>
          {file.name}
        </span>
      </Center>
    );
  }

  const ValueComponent: FileInputProps["valueComponent"] = ({ value }: any) => {
    if (Array.isArray(value)) {
      return (
        <Group py="xs">
          {value.map((file, index) => (
            <Value file={file} key={index} />
          ))}
        </Group>
      );
    }

    return <Value file={value} />;
  };
  return (
    <FileInput
      value={test}
      onChange={(e) => {
        console.log(e);
        setTest(e);
        onFileUpload(e);
      }}
      required={nullable ? false : true}
      error={error}
      label={label}
      disabled={disabled}
      valueComponent={ValueComponent}
      leftSection={<IconUpload size={14} />}
    />
  );
}
