import { ActionIcon, Collapse, Divider, Flex, Group, LoadingOverlay, Paper, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";

interface IProps {
  option: IItem[];
  defaultCollapse?: number[];
  disabled?: boolean;
  loading?: boolean;
}

interface IItem {
  title: any;
  subTitle: any;
  children?: any;
  iconNotcollapsed?: any;
  iconCollapsed?: any;
}

export function MultiCollapse({ loading = false, option = [], defaultCollapse = [], disabled = false }: IProps) {
  const [collapse, setCollapse] = useState<number[]>(defaultCollapse);
  const { classes } = useStyle();

  return (
    <div className={classes.container}>
      <Paper radius="sm" p="md" withBorder>
        {option.map((item: IItem, index: number) => {
          return (
            <div key={index}>
              <Text
                onClick={() => {
                  if (!disabled) setCollapse(collapse.includes(index) ? collapse.filter((f) => f !== index) : [...collapse, index]);
                }}
                component="a"
                key={index}>
                <Group align="center" justify="space-between" className={classes.header}>
                  <Flex direction="column">
                    <Text size="lg" fw={600} component="div">
                      {item.title}
                    </Text>
                    <Text size="sm" fw={400} c="dimmed">
                      {item.subTitle}
                    </Text>
                  </Flex>
                  <ActionIcon>
                    {collapse.includes(index) ? item.iconNotcollapsed || <IconChevronDown /> : item.iconCollapsed || <IconChevronRight />}
                  </ActionIcon>
                </Group>
              </Text>
              <Collapse in={collapse.includes(index)}>
                <Space h="md" />
                <Divider h={"md"} />
                <Paper className={classes.childrenWrapper}>{loading ? <LoadingOverlay visible={true} /> : item.children}</Paper>
              </Collapse>
              {option.length - 1 !== index && (
                <>
                  <Space h="md" />
                  <Divider h={"md"} />
                </>
              )}
            </div>
          );
        })}
      </Paper>
    </div>
  );
}

const useStyle = createStyles(() => ({
  childrenWrapper: {
    position: "relative",
  },
  container: {},
  header: {
    cursor: "pointer",
  },
}));
