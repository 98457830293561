import { ActionIcon, Flex, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconArrowLeft, IconFileDollar, IconFileInvoice } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { InvoiceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ClosedInvoice } from "./closed-invoice";
import { OpenedInvoice } from "./opened-invoice";

type IFileItem = {
  name: string;
  description: string;
  url: string;
};

type IFormData = {
  senderStaffId: string | undefined;
  senderBranchId: string | undefined;
  senderAccId: string | undefined;
  receiverBusinessId: string | undefined;
  paymentDate: Date | string | undefined;
  receiverBranchId: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountValue: number;
  shippingAmount: number;
  lines: any[];
  send: boolean;
  attachments: IFileItem[];
  refCode: string;
  orderSalesCode: string | null;
  purchaseSalesCode: string | null;
};

export const OurBuyerDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = useState<string>("1");

  const { data, isLoading } = useSwr<IFormData>(`/api/invoice/${params.id}`, async () => await InvoiceApi.get(`${params.id}`), {
    fallback: {},
  });

  return (
    <PageLayout
      title={
        <Flex gap={8} align="center">
          <ActionIcon onClick={() => navigate(-1)} variant="outline" size="sm" color="brand">
            <IconArrowLeft />
          </ActionIcon>
          <Text> ГЭРЭЭТ BUYER-ИЙН ДЭЛГЭРЭНГҮЙ </Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}>
      <Tabs
        onChange={(e: string | null) => {
          if (e) setTabKey(e);
        }}
        value={tabKey}>
        <Tabs.List>
          <Tabs.Tab color="brand" p={"xs"} value="1" leftSection={<IconFileDollar size={"1.2rem"} />}>
            Нээлттэй нэхэмжлэх
          </Tabs.Tab>
          <Tabs.Tab color="brand" p={"xs"} value="2" leftSection={<IconFileInvoice size={"1.2rem"} />}>
            Хаагдсан нэхэмжлэх
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1" pt="xs" pos={"relative"} mih={"40vh"}>
          <Paper>{isLoading ? <LoadingOverlay visible /> : <OpenedInvoice data={data} />}</Paper>
        </Tabs.Panel>
        <Tabs.Panel value="2" pt="xs" pos={"relative"} mih={"40vh"}>
          {isLoading ? <LoadingOverlay visible /> : <ClosedInvoice data={data} />}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/sales-invoice",
    label: "Манай авах",
  },
  {
    label: "Buyer-ийн дэлгэрэнгүй",
  },
];
