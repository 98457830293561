import { ActionIcon, Anchor, FileInput, Flex, Space } from "@mantine/core";
import { IconCheck, IconCloudUpload, IconMinus, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useField } from ".";
import { MediaApi } from "../../apis";
import { message } from "../../utils/Message";

export type IFile = {
  uid: string;
  uploading: boolean;
  url?: string;
  selected: boolean;
  file: File;
};

type Props = {
  onChange?: (files: IFile[]) => void;
  error: any;
  value: any;
  label?: string;
  required?: boolean;
};

function MultiFileUpload({ onChange, value, error, label, required }: Props) {
  const resetRef = React.useRef<() => void>(null);
  const [files, setFiles] = React.useState<{ [key: string]: any }>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isloading, setLoading] = useState<boolean>(true);

  const onFileUpload = (blobs: File[]) => {
    setLoading(true);
    if (blobs.length > 0) {
      if (blobs.some((som: any) => som.size < 10485760)) {
        const uploads = blobs.map((blob: any, index: any) => {
          const uid = uuidv4();
          (async () => {
            try {
              const form = new FormData();
              form.append("file", blob);

              const res = await MediaApi.uploadFile(form);

              setFiles((state) => ({
                ...state,
                [uid]: {
                  ...state[uid],
                  isUpload: true,
                  url: res.url,
                },
              }));
            } catch (err) {}
          })();

          return {
            uid: uid,
            file: blob,
            isUpload: false,
            url: null,
          };
        });

        setFiles((state) => ({
          ...state,
          ...uploads.reduce((accumulator, iterator) => {
            return { ...accumulator, [iterator.uid]: iterator };
          }, {}),
        }));

        resetRef.current?.();
      } else {
        message.error("Файлын хэмжээ хэтэрсэн байна.");
      }
    } else setFiles({});
    setLoading(true);
  };

  useEffect(() => {
    if (onChange) onChange(Object.keys(files).map((item: any) => files[item].url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div>
      <FileInput
        required={required}
        label={label}
        error={error}
        variant="filled"
        value={[]}
        onChange={(e) => onFileUpload(e)}
        multiple
        leftSection={<IconCloudUpload size={"1.2rem"} />}
      />
      <Space h={"xs"} />
      {Object.keys(files).map((item: any, index) => {
        return (
          <div key={index}>
            <>
              <Flex align={"center"} gap={"xs"} w={"100%"} justify={"start"}>
                <Anchor href={files[item].url} fz={"sm"} truncate w={"100%"}>
                  <Flex justify={"start"} align={"center"} gap={"xs"}>
                    <Flex align={"center"}>{files[item].isUpload ? <IconCheck color="green" size={"1.2rem"} /> : <IconX color="red" size={"1.2rem"} />}</Flex>
                    {files[item].file.name}
                  </Flex>
                </Anchor>
                <ActionIcon
                  onClick={() => {
                    setFiles(
                      Object.keys(files)
                        .filter((item2: any) => files[item2].url !== files[item].url)
                        .map((item: any) => files[item]),
                    );
                  }}
                  variant="light"
                  color="red"
                  loading={!files[item].isUpload}>
                  <IconMinus size={"1.2rem"} />
                </ActionIcon>
              </Flex>
            </>
            <Space h={"xs"} />
          </div>
        );
      })}
    </div>
  );
}

export function MultiFileUploadField({
  placeholder,
  name,
  label,
  required = false,
}: {
  placeholder?: string;
  name: string;
  label?: string;
  required?: boolean;
}) {
  const { value, error, onChange } = useField(name);

  return <MultiFileUpload onChange={onChange} value={value} error={error} label={label} required={required} />;
}
