import { Grid, Input, Paper, Text } from "@mantine/core";
import { dateTimeFormat } from "../../utils/date";

export function BuyerSenderDetailsForm({ values }: { values: any }) {
  return (
    <Paper>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Партнер байгууллагын нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.senderBusiness?.partner?.businessName ?? "-"}, #{values?.senderBusiness?.partner?.refCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Партнерийн ТТД" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.senderBusiness?.regNumber ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Нийлүүлэгч бизнесийн нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.senderBusiness?.profileName ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Борлуулалтын захиалга" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.salesOrderCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Худалдан авалтын захиалга" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.purchaseOrderCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Нэхэмжлэх бичсэн огноо" required>
            <Text c="brand" fz="sm" fw={500}>
              {values.createdAt ? dateTimeFormat(values.createdAt) : dateTimeFormat(new Date())}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Санхүүгийн ажилтан" required>
            <Text c="brand" fz="sm" fw={500} tt={"capitalize"}>
              {values?.senderFinUser?.lastName ? `${values?.senderFinUser?.lastName[0]}.` : ""} {values?.senderFinUser?.firstName ?? ""}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Бизнесийн хаяг" required>
            <Text c="brand" fz="sm" fw={500}>
              {values.senderBranch?.branchAddress ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <Input.Wrapper label="Төлбөр хүлээн авах данс" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.senderAcc?.name ?? "-"} / {values?.senderAcc?.number ?? "_"}{" "}
              <Text component="span" c="gray">
                {values?.senderAcc?.bankName}
              </Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
