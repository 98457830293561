import { ActionIcon, Divider, Flex, Paper, Tabs, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { IAuth } from "../../interfaces/IAuth";
import { InvioceClosed } from "./invoice-closed";
import { InvioceOpen } from "./invoice-open";
import { InviocePending } from "./invoice-pending";

export const BillingDetails = () => {
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<any>({ type: "CONFIRMED", businessId: params.id! });
  const location = useLocation();
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const INV_NET_SETT = usePermission("INV_NET_SETT");

  return (
    <PageLayout
      title={
        <Flex align="center" gap={"xs"}>
          <span>
            <ActionIcon onClick={() => navigate(-1)} variant="outline" color="" size={"sm"}>
              <IconArrowLeft />
            </ActionIcon>
          </span>
          {myBusinessType === "BUYER" ? "Нийлүүлэгчийн дэлгэрэнгүй" : "Харилцагчийн дэлгэрэнгүй"}{" "}
          <Text c="brand" component="span">
            {location?.state?.refCode}#, {location?.state?.profileName}
          </Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}>
      <Divider />
      <Tabs
        onChange={(e: string | null) => {
          setFilters({ ...filters, type: e });
        }}
        value={filters.type}
        defaultValue={"CONFIRMED"}>
        <Tabs.List>
          <Tabs.Tab p={"md"} value="CONFIRMED">
            <Text c={filters.type === "CONFIRMED" ? "brand" : "black"} fw={500}>
              Нээлттэй нэхэмжлэх
            </Text>
          </Tabs.Tab>
          <Tabs.Tab p={"md"} value="PENDING">
            <Text c={filters.type === "PENDING" ? "brand" : "black"} fw={500}>
              Батлах хүлээж буй
            </Text>
          </Tabs.Tab>
          <Tabs.Tab p={"md"} value="CLOSED">
            <Text c={filters.type === "CLOSED" ? "brand" : "black"} fw={500}>
              Хаагдсан нэхэмжлэх
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        {INV_NET_SETT.hasAccess ? (
          <Paper pt="md">
            <Tabs.Panel value="CONFIRMED">{filters.type === "CONFIRMED" && <InvioceOpen filters={filters} />}</Tabs.Panel>
            <Tabs.Panel value="PENDING">{filters.type === "PENDING" && <InviocePending filters={filters} />}</Tabs.Panel>
            <Tabs.Panel value="CLOSED">{filters.type === "CLOSED" && <InvioceClosed filters={filters} />}</Tabs.Panel>
          </Paper>
        ) : (
          INV_NET_SETT.accessDenied()
        )}
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нэхэмжлэх удирдлага",
  },
  {
    label: "Харилцагчийн тооцоо",
  },
  {
    label: "Дэлгэрэнгүй",
  },
];
