import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/inv/auth/me");
  return res;
};

export const createPin = async (data: any) => {
  const res = await httpRequest.post("/2fa/auth/pin", data);
  return res;
};

export const updatePin = async (data: any) => {
  const res = await httpRequest.put("/2fa/auth/pin", data);
  return res;
};

export const updateProfile = async (data: any) => {
  const res = await httpRequest.put("/inv/auth/profile", data);
  return res;
};

export const updateAvatar = async (data: any) => {
  const res = await httpRequest.put("/inv/auth/avatar", data);
  return res;
};
