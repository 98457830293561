import { Box, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { forwardRef, useEffect, useState } from "react";
import useSwr, { mutate } from "swr";
import { NetworkApi, branchApi } from "../../apis";
import { useBusiness } from "../../pages/sales-invoice/new";
import { DatePickerField } from "../form/datepicker-field";
import { SelectField } from "../form/select-field";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  refCode: string;
  label: string;
  item: string;
}

export function InvoiceReceiverForm({ values, setFieldValue }: any) {
  const [businessData, setBusinessData] = useState<any>();
  const [queryBusiness, setQueryBusiness] = useState<string | null>("");
  const [queryBranch, setQueryBranch] = useState<string | null>("");
  const [businessId, setBusinessId] = useState<string | null>("");
  const { getBusiness, isNew } = useBusiness();

  const { data: branchesList } = useSwr(
    businessId ? `/api/branch?businessId=${businessId}&query=${queryBranch}` : [],
    businessId ? async () => await branchApi.select({ query: queryBranch, businessId }) : null,
    {
      fallback: [],
    },
  );

  const { data: businessList = [] } = useSwr(
    `/api/network?&query=${queryBusiness ?? ""}`,
    async () => await NetworkApi.select({ query: queryBusiness ?? "" }),
    {
      fallback: [],
    },
  );

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ refCode, label, item, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            #{refCode}
          </Text>
        </div>
      </Group>
    </div>
  ));

  const onSearchBranch = async (query: string | "", businessId: any) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setBusinessId(businessId);
      setQueryBranch(query);
    }, 400);
  };

  const onSearchBusiness = async (query: string | null) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setQueryBusiness(query);
    }, 400);
  };

  const getBusinessOnChange = async (id: string) => {
    let res = null;
    try {
      if (id) res = await NetworkApi.get(id);
      getBusiness(id);
      setBusinessData(res);
      setFieldValue("paymentDate", res?.paymentDate ?? null);

      setBusinessId(id);
      setQueryBranch("");

      mutate(`/api/branch?businessId=${id}&query=${queryBranch}`);
    } catch (error) {
      console.log(error);
    }
  };

  const SelectItem2 = forwardRef<HTMLDivElement, ItemProps>(({ branchAddress, label, item, ...others }: any, ref) => (
    <Box ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.5}>
            {branchAddress}
          </Text>
        </div>
      </Group>
    </Box>
  ));

  useEffect(() => {
    if (values?.receiverBusinessId) {
      getBusiness(values?.receiverBusinessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNew === true && branchesList?.length) {
      const headOfficeBranch = branchesList.find((item: { branch: { type: string } }) => item.branch.type === "HEAD_OFFICE");
      if (headOfficeBranch) {
        setFieldValue("receiverBranchId", headOfficeBranch.branch.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesList, isNew]);

  return (
    <Paper>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectField
            itemComponent={SelectItem}
            searchable
            clearable
            onChange={(e: any) => {
              getBusinessOnChange(e);
              onSearchBranch("", e);
              setFieldValue("receiverBranchId", undefined);
              setFieldValue("paymentDate", undefined);
            }}
            onSearchChange={(e) => onSearchBusiness(e)}
            label="Худалдан авагч бизнесийн нэр"
            placeholder="Худалдан авагч бизнесийн нэр"
            name="receiverBusinessId"
            options={
              businessList?.map((item: any) => {
                return {
                  value: item.id,
                  label: item.profileName,
                  refCode: item.refCode,
                };
              }) ?? []
            }
            required
          />
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectField
            searchable
            clearable
            disabled={!businessData}
            label="Хүлээн авах хаяг"
            placeholder="Хүлээн авах хаяг"
            name="receiverBranchId"
            onSearchChange={(e) => onSearchBranch(e === "-" ? "" : e, values?.receiverBusinessId === "-" ? "" : values?.receiverBusinessId)}
            options={
              branchesList && branchesList?.length > 0
                ? branchesList.map((item: any) => {
                    return {
                      value: item?.branch?.id,
                      label: `${item?.branch?.name ?? "-"}`,
                      branchAddress: `${item?.branch?.branchAddress ?? ""}`,
                    };
                  })
                : []
            }
            itemComponent={SelectItem2}
            required
          />
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <DatePickerField name="paymentDate" label="Нэхэмжлэх төлөх огноо" placeholder="Авто бодогдоно. Сольж болно" disabled={!businessData} />
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Партнер байгууллагын нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {businessData?.partner?.businessDataName ?? "-"} {businessData?.partner?.refCode ? `, #${businessData?.partner?.refCode}` : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар" required>
            <Text c="brand" fz="sm" fw={500}>
              {businessData?.regNumber ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Төлбөрийн нөхцөл" required>
            <Text c="brand" fz="sm" fw={500}>
              {businessData?.paymentTerm?.configType ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Бизнесийн хаяг" required>
            <Text c="brand" fz="sm" fw={500}>
              {businessData?.partner?.businessAddress ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Санхүүгийн ажилтан" required>
            <Text c="brand" fz="sm" fw={500} tt={"capitalize"}>
              {businessData?.staff?.lastName ? `${businessData?.staff?.lastName[0]}.` : ""} {businessData?.staff?.firstName ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Дансны мэдээлэл" required>
            {businessData?.bankAccount !== null ? (
              <Text c="brand" fz="sm" fw={500}>
                {businessData?.bankAccount?.name ?? "_"} / {businessData?.bankAccount?.number ?? "_"}{" "}
                <Text component="span" c="gray">
                  {values?.receiverAcc?.bankName}
                </Text>
              </Text>
            ) : (
              <Text c="red" fz="sm" fw={500}>
                Харилцагч данс тохиуулаагүй.
              </Text>
            )}
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
