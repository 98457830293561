import { Badge, MantineColor } from "@mantine/core";

const Tag = (color: MantineColor, name: string) => {
  if (color.slice(0, 1) === "#")
    return (
      <Badge
        style={{ border: `1px solid ${color}`, color: color }}
        size="md"
        radius="lg"
        variant="outline"
        styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {name}
      </Badge>
    );
  else
    return (
      <Badge color={color} size="md" radius="lg" variant="outline" styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {name}
      </Badge>
    );
};

export { Tag };
