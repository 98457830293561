import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

type IHistoryType = {
  receiverBusinessId: string;
  paymentMethod: string;
  totalAmount: number;
  trxRefCode: string;
  invoices: {
    invoiceId: string;
    amount: number;
  }[];
  files: any[];
};

export const business_select = async (data: any) => {
  return httpRequest.post("/inv/invoice/history/business_select", data);
};

export const invoice_history = async (data: IHistoryType) => {
  return httpRequest.put(`/inv/invoice/history`, data);
};

export const invoice_list = async (data: any) => {
  return httpRequest.put(`/inv/invoice/history/invoice_list`, data);
};

export const list = async (data: any) => {
  return httpRequest.put(`/inv/invoice/history`, data);
};
