import { Box, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useSwr from "swr";
import { branchApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { useBusiness } from "../../pages/sales-invoice/new";
import { dateTimeFormat } from "../../utils/date";
import { SelectField } from "../form/select-field";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  account: string;
  label: string;
  item: string;
  shortName: string;
  bankName: string;
}

export function InvoiceSenderForm({ values, setFieldValue }: any) {
  const now = new Date();
  const params = useParams();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { bankAccounts = [] } = useSelector((state: any) => state.general);
  const { business } = useBusiness();

  useEffect(() => {
    if (business?.inAccount?.id) {
      setFieldValue("senderAccId", business.inAccount.id);
    } else {
      setFieldValue("senderAccId", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const [query, setQuery] = useState<string>("");

  const { data: businessList } = useSwr(
    user?.currentBusiness.id ? `/api/branch?businessId=${user.currentBusiness.id}&query=${JSON.stringify(query)}` : null,
    user?.currentBusiness.id ? async () => await branchApi.select({ businessId: user.currentBusiness.id, query: query }) : null,
    {
      fallback: [],
    },
  );

  useEffect(() => {
    if (businessList?.length) {
      const headOfficeBranch = businessList.find((item: { branch: { type: string } }) => item.branch.type === "HEAD_OFFICE");
      if (headOfficeBranch) {
        setFieldValue("senderBranchId", headOfficeBranch.branch.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessList]);

  const onSearchBranch = async (query: string) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setQuery(query);
    }, 1000);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ account, label, item, bankName, shortName, ...others }: ItemProps, ref) => {
    return (
      <Box ref={ref} {...others}>
        <Group>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.5}>
            дугаар: {account} / {shortName}
            <Text component="span" c="gray">
              {bankName}
            </Text>
          </Text>
        </Group>
      </Box>
    );
  });

  const SelectItem2 = forwardRef<HTMLDivElement, ItemProps>(({ branchAddress, label, item, ...others }: any, ref) => (
    <Box ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.5}>
            {branchAddress}
          </Text>
        </div>
      </Group>
    </Box>
  ));

  return (
    <Paper>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Партнер байгууллагын нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {params.id === "new" ? user?.partner?.businessName ?? "-" : values?.senderBusiness?.partner?.businessName ?? "-"}, #
              {params.id === "new" ? user?.partner?.refCode ?? "-" : values?.senderBusiness?.partner?.refCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Партнерийн ТТД" required>
            <Text c="brand" fz="sm" fw={500}>
              {params.id === "new" ? user?.partner?.regNumber ?? "-" : values?.senderBusiness?.regNumber ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нийлүүлэгч бизнесийн нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {params.id === "new" ? user?.currentBusiness?.profileName ?? "-" : values?.senderBusiness?.profileName ?? "-"}, #
              {params.id === "new" ? user?.currentBusiness?.refCode ?? "-" : values?.senderBusiness?.refCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Борлуулалтын захиалга" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.salesOrderCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Худалдан авалтын захиалга" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.purchaseOrderCode ?? "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нэхэмжлэх бичсэн огноо" required>
            <Text c="brand" fz="sm" fw={500}>
              {dateTimeFormat(params.id === "new" ? now : values.createdAt)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Санхүүгийн ажилтан" required>
            <Text w={"max-content"} c="brand" fz="sm" fw={500} tt={"capitalize"}>
              {user?.lastName ? user?.lastName[0] + ". " : ""}
              {user?.firstName}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectField
            searchable
            clearable
            label="Бизнесийн хаяг"
            placeholder="Бизнесийн хаяг"
            name="senderBranchId"
            onSearchChange={(e) => {
              onSearchBranch(e);
            }}
            options={
              businessList?.map((item: any, _index: number) => {
                return {
                  value: item?.branch?.id,
                  label: `${item?.branch?.name ?? "-"}`,
                  branchAddress: `${item?.branch?.branchAddress ?? ""}`,
                };
              }) ?? []
            }
            itemComponent={SelectItem2}
            required
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectField
            searchable
            itemComponent={SelectItem}
            clearable
            label="Төлбөр хүлээн авах данс"
            placeholder="Төлбөр хүлээн авах данс"
            name="senderAccId"
            options={(bankAccounts ?? []).map((item: any) => {
              return {
                value: item.id,
                label: `${item.name} ${item.number}`,
              };
            })}
            required
            disabled={business?.inAccount?.id}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
