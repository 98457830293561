import { MantineTheme, MantineThemeOverride } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          border: "0 solid #e5e7eb",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    brand: ["#FFF3ED", "#fce9d0", "#ffd09c", "#fdb766", "#fca13a", "#fb931d", "#FF854B", "#ff7b3e", "#ff7331", "#f2621f"],
  },
  primaryColor: "brand",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 15,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};
