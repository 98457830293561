import { Grid, Input, Paper, Text } from "@mantine/core";
import { dateTimeFormat } from "../../utils/date";

export function InvoiceReceiverDetailsForm({ values }: any) {
  return (
    <Paper>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Худалдан авагч бизнесийн нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverBusiness?.profileName || "-"} {values?.receiverBusiness?.refCode ? values?.receiverBusiness?.refCode : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Хүлээн авах хаяг" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverBranch?.branchAddress || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо" required>
            <Text c="brand" fz="sm" fw={500}>
              {values.paymentDate ? dateTimeFormat(values.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Партнер байгууллагын нэр" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverBusiness?.partner?.businessName || "-"}{" "}
              {values?.receiverBusiness?.partner?.refCode ? `, #${values?.receiverBusiness?.partner?.refCode}` : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverBusiness?.regNumber || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Төлбөрийн нөхцөл" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.paymentTerm?.description || "-"} / {values?.paymentTerm?.configType || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Бизнесийн хаяг" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverBranch?.branchAddress || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Санхүүгийн ажилтан" required>
            <Text c="brand" fz="sm" fw={500} tt={"capitalize"}>
              {values?.receiverFinUser?.lastName ? `${values?.receiverFinUser?.lastName[0]}. ` : ""} {values?.receiverFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Input.Wrapper label="Дансны мэдээлэл" required>
            <Text c="brand" fz="sm" fw={500}>
              {values?.receiverAcc?.name || "_"} / {values?.receiverAcc?.number || "_"} <Text component="span">{values?.receiverAcc?.bankName}</Text>
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
