import { ActionIcon, Avatar, Box, Group, Text, Tooltip } from "@mantine/core";
import { IconFileText } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NetworkSettlementApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const InviocePending = ({ filters }: { filters: any }) => {
  const { paymentTermConfigTypes, paymentTermConditions } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "details":
          navigate(`/customer-billing/${record.id}/history`, { state: record });
          break;

        default:
          break;
      }
    },
    paymentTermConfigTypes,
    paymentTermConditions,
  });

  return (
    <div>
      <Table name="invoice.confirm.pending" columns={columns} filters={filters} loadData={(data) => NetworkSettlementApi.invoice(data!)} />
    </div>
  );
};

const useHeader = ({
  onClick,
  paymentTermConfigTypes,
  paymentTermConditions,
}: {
  onClick: (key: string, record: any) => void;
  paymentTermConfigTypes: IReference[];
  paymentTermConditions: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    width: "100px",
    render: (record) => (
      <Group>
        <Tooltip label="Дэлгэрэнгүй" disabled>
          <ActionIcon color="" onClick={() => onClick("details", record)}>
            <IconFileText />
          </ActionIcon>
        </Tooltip>
      </Group>
    ),
  },
  {
    title: "Илгээсэн огноо",
    render: (record) => (
      <Text size="md" w="max-content">
        {record?.sentDate ? dateTimeFormat(record.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => <Text size="md">#{record?.refCode ?? "-"}</Text>,
  },
  {
    title: "Нэхэмжлэхийн дүн",
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record.amountToPay)}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн нөхцөл",
    render: (record) => (
      <Box w={"max-content"}>
        <Text size="sm">{paymentTermConditions.find((f: IReference) => f.code === record?.paymentTerm?.condition)?.name ?? "-"}</Text>
        <Text size="sm" c="dimmed">
          {record?.paymentTerm?.description}
        </Text>
      </Box>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    render: (record) =>
      record?.senderUser ? (
        <Group w={"max-content"}>
          <Avatar size={32} src={record?.senderUser?.avatar} radius={"xl"} />
          <Text tt={"capitalize"}>
            <Text size="sm">{record?.senderUser?.lastName ? record?.senderUser?.lastName[0] + "." : ""}</Text>{" "}
            <Text size="sm">{record?.senderUser?.firstName ?? "-"}</Text>
          </Text>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "Захиалгын дугаар",
    render: (record) => <Text size="sm">{record?.orderSalesCode ?? "-"}</Text>,
  },
];
