import { Group, Paper, Space, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useScreenDetector } from "../screen-detector";

type IFilter = {
  date: string;
  confirmedStartDate: string;
  confirmedEndDate: string;
  pendingStartDate: string;
  pendingEndDate: string;
  categoryId: string;
};

export function ApprovedInvoiceChart({ data, filter, setFilter }: { data: any[]; filter: IFilter; setFilter: React.Dispatch<React.SetStateAction<IFilter>> }) {
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();

  const getOptions = () => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "bottom",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data?.map((item: any) => {
          return item.date;
        }),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 20,
    },
    series: [
      {
        name: "Баталсан",
        color: theme.colors.brand[0],
        type: "column",
        data: data?.map((item: any) => {
          return item.confirmedAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Төлөгдсөн",
        type: "column",
        color: theme.colors.brand[6],
        data: data?.map((item: any) => {
          return item.paidAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  return (
    <Paper withBorder py={"xs"}>
      <Group align="apart">
        <Group>
          <div className={classes.grdnt}></div>
          <Text fw={600}>Баталсан нэхэмжлэх</Text>
        </Group>
        <DatePickerInput
          type="range"
          onChange={(e: any) => {
            if (e[0] && e[1]) setFilter({ ...filter, confirmedStartDate: e[0], confirmedEndDate: e[1] });
            if (!e[0] && !e[1])
              setFilter({
                ...filter,
                confirmedStartDate: dayjs(new Date()).subtract(14, "day").format(),
                confirmedEndDate: dayjs(new Date()).format(),
              });
          }}
          value={[new Date(filter.confirmedStartDate), new Date(filter.confirmedEndDate)]}
          style={{ borderRadius: isMobile ? 0 : "5px" }}
          variant="unstyled"
          bg={theme.colors.brand[0]}
          miw={isMobile ? "100%" : "240px"}
          placeholder="Огноо хайх"
          leftSection={<IconCalendar size={"1.2rem"} />}
          valueFormat="YYYY-MM-DD"
          mr={isMobile ? 0 : "sm"}
        />
      </Group>
      <Space h={"md"} />
      <Paper px={"lg"}>
        <HighchartsReact highcharts={Highcharts} options={getOptions()} />
        <Group align="right" style={{ marginTop: -20 }}>
          <Text size={"sm"} c="dimmed" style={{ zIndex: 2 }} bg={"white"}>
            Хэмжих нэгж: Сая төгрөгөөр
          </Text>
        </Group>
      </Paper>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.colors.brand[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
