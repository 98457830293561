import { Avatar, Button, Flex, Group, Input, Modal, Paper, Space, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { dateTimeFormat } from "../../utils/date";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, RowAction, Table } from "../table";
import { InvoiceAttachmentModalForm } from "./invoice-attachment-modal-form";

type IFileItem = {
  name: string | undefined;
  description: string | undefined;
  url: string | undefined;
  date: string | undefined;
  attachedUser?: string | undefined;
  id: string;
  createdAt: Date | undefined;
};

export function InvoiceAttachmentForm({ values, setFieldValue }: any) {
  const [action, setAction] = useState<any>([]);
  const columns = useHeader({
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue(
          "attachments",
          values.attachments.filter((f: any) => f.id !== item.id),
        );
      } else if (key === "edit") setAction([key, item]);
    },
  });

  return (
    <Paper>
      <Flex direction="column" gap={8}>
        <Group>
          <Input placeholder="Хайх..." w={"100%"} leftSection={<IconSearch size={18} />} />
          <Button onClick={() => setAction(["add"])}>Файл нэмэх</Button>
        </Group>
        <Table columns={columns} name="attachments" dataSource={values.attachments} />
      </Flex>
      <Modal
        radius="sm"
        opened={action[0] === "add" || action[0] === "edit"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="xl"
        centered>
        <InvoiceAttachmentModalForm action={action} setAction={setAction} values={values} setFieldValue={setFieldValue} />
      </Modal>
    </Paper>
  );
}

const useHeader = ({ onClick }: { onClick: (e: string, record: IFileItem) => void }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    width: "140px",
    render: (record) => `${record?.name ?? "-"}`,
  },
  {
    title: "Хавсралт файл",
    dataIndex: "url",
    width: "150px",
    render: (record) =>
      record?.url ? (
        <Text component="a" href={record?.url}>
          {record?.url?.slice(66, 90)}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Огноо",
    sorter: true,
    dataIndex: "date",
    width: 220,
    render: (record) => `${record.createdAt ? dateTimeFormat(record.createdAt) : dateTimeFormat(new Date())}`,
  },
  {
    title: "Хавсаргасан",
    sorter: true,
    dataIndex: "attachedUser",
    render: (record) => {
      return (
        <Flex align="center">
          <Avatar src={record?.attachedUser?.avatar ?? "#"} />
          <Space w="xs" />
          <Text tt="capitalize">
            {record?.attachedUser?.lastName ? `${record?.attachedUser?.lastName[0]}.` : ""}
            {record?.attachedUser?.firstName ?? ""}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record.description}</PopoverText>,
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <RowAction
          onClick={(key) => onClick(key, record)}
          extra={{
            edit: "Засах",
            remove: "Устгах",
          }}
        />
      );
    },
  },
];
