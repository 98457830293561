import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChartPie, IconCoins, IconFileInvoice, IconReceipt, IconReceiptOff, IconUserDollar } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { useScreenDetector } from "../screen-detector";
import { CommingSoon } from "./comming-soon";

const items = [
  { title: "Дашбоард", icon: IconChartPie, color: "", link: "/", disabled: false, menu: "INVOICE_DASHBOARD" },
  { title: "Нээлттэй нэхэмжлэх", icon: IconReceipt, color: "", link: "/sales-invoice", disabled: false, menu: "INVOICE_LIST" },
  { title: "Нээлттэй нэхэмжлэх", icon: IconFileInvoice, color: "", link: "/buyer-invoice", disabled: false, menu: "INVOICE_LIST" },
  { title: "Хаагдсан нэхэмжлэх", icon: IconReceiptOff, color: "", link: "/closed-invoice", disabled: false, menu: "INVOICE_LIST_CLOSED" },
  { title: "Харилцагчийн тооцоо", icon: IconUserDollar, color: "", link: "/customer-billing", disabled: false, menu: "INVOICE_NETWORK_SETTLEMENT" },
  { title: "Тооцоо нийлэх", icon: IconCoins, color: "", link: "/settlement", disabled: false, menu: "INVOICE_SETTLEMENT" },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile } = useScreenDetector();
  const isHide = myBusinessType !== "BUYER" ? "/buyer-invoice" : "/sales-invoice";
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const test = user?.permissions?.filter((fil: any) => fil.module === "INVOICE");

  return (
    <AppShell.Navbar p="md">
      {items
        .filter((f) => f.link !== isHide)
        .map((item, index) => {
          return (
            <NavItem
              item={{ ...item, disabled: !test?.some((som: any) => som.menu === item.menu) }}
              key={index}
              setOpened={setOpened}
              opened={isMobile && opened}
            />
          );
        })}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "brand" });

  return (
    <CommingSoon position="right" visible={item.disabled}>
      <NavLink
        className={cx(classes.item, item.disabled && "disabled", opened && classes.row)}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={opened ? 0 : 7} lh="16px" fw="400">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }, u) => {
  const icon = u.ref("icon");

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "20px",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,

      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
