import { Collapse, Divider, Group, Paper, Text, UnstyledButton } from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NetworkSettlementApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const SalesInvoicesList = ({
  filters,
  title,
}: {
  title: string;
  filters: {
    businessId: string;
    type: string;
  };
}) => {
  const [opened, setOpened] = useState(true);
  const { overdueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ overdueStatus, filters });

  return (
    <Paper withBorder px={"xs"}>
      <UnstyledButton w={"100%"} onClick={() => setOpened(!opened)}>
        <Group align="apart">
          <Text component="h1">{title}</Text>
          {!opened ? <IconChevronRight color="gray" size={"1.2rem"} /> : <IconChevronDown color="gray" size={"1.2rem"} />}
        </Group>
      </UnstyledButton>
      <Collapse
        in={opened}
        children={
          <>
            <Divider />
            <Table name="inv.network_settlement.list" columns={columns} filters={filters} loadData={(data) => NetworkSettlementApi.invoice(data!)} />
          </>
        }
      />
    </Paper>
  );
};

const useHeader = ({ overdueStatus, filters }: { overdueStatus: IReference[]; filters: any }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_record, index) => {
      return <Text size="sm">{index + 1}</Text>;
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    dataIndex: "refCode",
    render: (record) => {
      return <Text size="sm">#{record?.refCode}</Text>;
    },
    width: "100px",
  },
  {
    title: "Нэхэмжлэхийн дүн",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return (
        <Text ta="end" size="sm">
          {currencyFormat(record.confirmedAmount)}
        </Text>
      );
    },
  },
  {
    title: "Харилцагч төлсөн",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return (
        <Text ta="end" size="sm">
          {currencyFormat(record.paidAmount)}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл төлбөр",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return (
        <Text size="sm" ta="end">
          {currencyFormat(record.amountToPay)}
        </Text>
      );
    },
  },
  {
    title: "Төлөлтийн статус",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return Tag(
        overdueStatus?.find((item: IReference) => item.code === record?.overdueStatus)?.color || "gray",
        overdueStatus?.find((item: IReference) => item.code === record.overdueStatus)?.name || record?.overdueStatus || "-",
      );
    },
  },
  {
    title: "Үлдсэн хоног",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return <Text size="sm">{dayjs(record.paymentDate).diff(dayjs(), "days")}</Text>;
    },
  },
  {
    title: "Дуусах огноо",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return <Text size="sm">{dateTimeFormat(record.paymentDate)}</Text>;
    },
  },
  {
    title: filters.type === "CLOSED" ? "Хаагдсан огноо" : "Баталсан огноо",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return <Text size="sm">{dateTimeFormat(filters.type === "CLOSED" ? record.paidDate : record.confirmedDate)}</Text>;
    },
  },
];
