import { useSelector } from "react-redux";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { IAuth } from "../../interfaces/IAuth";
import { RequestBuyer } from "./request-buyer";
import { RequestSupplier } from "./request-supplier";

export const InvoiceSettlementPage = () => {
  const breadcrumbs = useBreadcrumb();
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const INV_SETT = usePermission("INV_SETT");

  return (
    <PageLayout title="Тооцоо нийлэх" subTitle="Харилцагчтай тооцоо нийлэх" breadcrumb={breadcrumbs} extra={[]}>
      {INV_SETT.hasAccess ? (
        <>
          {myBusinessType === "BUYER" && <RequestBuyer />}
          {myBusinessType === "SUPPLIER" && <RequestSupplier />}
        </>
      ) : (
        INV_SETT.accessDenied()
      )}
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нэхэмжлэх удирдлага",
  },
  {
    label: "Тооцоо нийлэх",
  },
];
