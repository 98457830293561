export function CommingSoon({
  position = "right",
  children,
  visible = false,
  width = 400,
}: {
  position: "top" | "right" | "left" | "bottom";
  children: JSX.Element;
  visible?: boolean;
  width?: number;
}) {
  if (!visible) return children;

  return <></>;
}
