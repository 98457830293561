import { ActionIcon, Alert, Avatar, Badge, Button, Divider, Flex, Grid, Group, Modal, Paper, Text } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { IconDeviceFloppy, IconEdit, IconPhoto, IconPlus, IconRowInsertTop, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { ColumnType, Table } from "../table";
import { InvoiceProductItemEditForm } from "./invoice-product-item-edit-form";
import { InvoiceProductItemForm } from "./invoice-product-item-form";
import { InvoiceProductPackageForm } from "./invoice-product-package-form";

type InvoiceProductFormProps = {
  values: any;
  setFieldValue: (field: string, fallback: any) => void;
  errors: any;
};

export function InvoiceProductForm({ values, errors, setFieldValue }: InvoiceProductFormProps) {
  const { perTypes, vatTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [edit, setEdit] = useState<string>("");

  const [action, setAction] = useState<any>([]);

  const columns = useHeader({
    perTypes,
    edit,
    setEdit,
    vatTypes,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("lines", [...values?.lines?.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const columns2 = useHeader2({
    perTypes,
    edit,
    setEdit,
    onClick: (key, item) => {
      if (key === "remove_additionalLines") {
        setFieldValue("additionalLines", [...(values?.additionallines?.filter((item2: any) => item2.id !== item.id) ?? [])]);
      }
    },
  });

  return (
    <Paper>
      <Flex direction="column" gap={14}>
        <Group align="center" justify="space-between">
          <Button disabled={!values?.receiverBusinessId} onClick={() => setAction(["add", values])} variant="light" w={"36px"} h={"36px"} p={0}>
            <IconPlus />
          </Button>
          <Button disabled={!values?.receiverBusinessId} onClick={() => setAction(["package", values])} variant="light">
            Багцаар сонгох
          </Button>
        </Group>

        <Table columns={columns} name="values?.lines" dataSource={values?.lines || []} pagination={false} />

        <Divider h={"sm"} />
        <Group>
          <Text fz={"lg"} fw={600}>
            Нэмэлтээр
          </Text>
          <Button
            onClick={() => {
              setFieldValue("additionalLines", [
                ...(values?.additionalLines ?? []),
                {
                  id: randomId(),
                  nameMon: undefined,
                  price: undefined,
                  quantity: undefined,
                  isEdit: false,
                  isNew: true,
                  image: undefined,
                  name: undefined,
                  unit: undefined,
                  discountType: undefined,
                  discountValue: undefined,
                  additionalLines: true,
                  vatAmount: undefined,
                },
              ]);
            }}
            variant="light"
            w={"36px"}
            h={"36px"}
            p={0}>
            <IconRowInsertTop />
          </Button>
        </Group>

        <Table columns={columns2} name="values.additionalLines" dataSource={values?.additionalLines ?? []} pagination={false} />

        <Alert color="red" title={errors.lines} withCloseButton hidden={!errors.lines}>
          Та бараа оруулна уу.
        </Alert>
        <Grid>
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={12}>
                <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex gap={8}>
                  {values.discountType === "PERCENTAGE" ? (
                    <NumberField w={"80%"} max={100} disabled={!values.discountType} name="discountValue" label="Нийт дүнгийн хөнгөлөлт" placeholder="0.00 %" />
                  ) : (
                    <NumberCurrencyField
                      w={"80%"}
                      max={1000000000}
                      disabled={!values.discountType}
                      name="discountValue"
                      label="Нийт дүнгийн хөнгөлөлт"
                      placeholder="0.00 ₮"
                    />
                  )}
                  <SelectField
                    label=" "
                    onChange={() => {
                      setFieldValue("discountValue", undefined);
                    }}
                    noError
                    name="discountType"
                    options={[
                      { label: "Хувь", value: "PERCENTAGE" },
                      { label: "Дүн", value: "AMOUNT" },
                    ]}
                    placeholder="Сонгох"
                  />
                </Flex>
              </Grid.Col>
              <Grid.Col span={12}>
                <NumberCurrencyField name="shippingAmount" label="Хүргэлтын үнэ" placeholder="0.00" />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12}>
            <Paper p="md" withBorder>
              <Flex direction="column" gap={10}>
                <Group align="center" justify="flex-end">
                  <Text>Нийт барааны дүн</Text>
                  <Text ta="end" miw={120} fw={500}>
                    {currencyFormat(values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Тооцсон НӨАТ</Text>
                  <Text ta="end" miw={120} fw={500}>
                    {currencyFormat(values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Тооцсон НХАТ</Text>
                  <Text ta="end" miw={120} fw={500}>
                    {currencyFormat(values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Нэмэлт</Text>
                  <Text ta="end" miw={120} fw={500}>
                    {currencyFormat(values?.additionalLines?.reduce((total: number, item: any) => total + (item?.quantity || 0) * (item?.price || 0), 0) || 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Нийт дүнгийн хөнгөлөлт</Text>
                  {values.discountType === "AMOUNT" && (
                    <Text ta="end" miw={120} fw={500}>
                      {currencyFormat(values.discountValue || 0)}
                    </Text>
                  )}
                  {values.discountType === "PERCENTAGE" && (
                    <Text ta="end" miw={120} fw={500}>
                      {currencyFormat(
                        (values.discountValue * (values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)) /
                          100 || 0,
                      )}
                    </Text>
                  )}
                  {!values.discountType && (
                    <Text ta="end" miw={120} fw={500}>
                      0.00 ₮
                    </Text>
                  )}
                </Group>
                <Group align="center" justify="flex-end">
                  <Text>Хүргэлтийн төлбөр</Text>
                  <Text ta="end" miw={120} fw={500}>
                    {currencyFormat(parseFloat(values?.shippingAmount) || 0)}
                  </Text>
                </Group>
                <Group align="center" justify="flex-end">
                  <Text fw={500} c="indigo">
                    НИЙТ ТӨЛБӨР
                  </Text>
                  {values.discountType === "AMOUNT" ? (
                    <Text fw={500} c="indigo" ta="end" miw={120}>
                      {currencyFormat(
                        (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.price) || 0, 0) || 0) +
                          (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.vatAmount) || 0, 0) || 0) +
                          (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.taxAmount) || 0, 0) || 0) +
                          (parseFloat(values?.shippingAmount) || 0) -
                          (parseFloat(values.discountValue) || 0) +
                          (values?.additionalLines?.reduce((total: number, item: any) => (total || 0) + (item?.quantity || 0) * (item?.price || 0), 0) || 0),
                      )}
                    </Text>
                  ) : (
                    <Text fw={500} c="indigo" ta="end" miw={120}>
                      {currencyFormat(
                        (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.price) || 0, 0) || 0) +
                          (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.vatAmount) || 0, 0) || 0) +
                          (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.taxAmount) || 0, 0) || 0) +
                          (parseFloat(values?.shippingAmount) || 0) -
                          (((parseFloat(values?.discountValue) || 0) *
                            (values?.lines?.reduce((total: number, item: any) => total + parseFloat(item!.quantity) * parseFloat(item?.price) || 0, 0) || 0)) /
                            100 || 0) +
                          (values?.additionalLines?.reduce((total: number, item: any) => total + (item?.quantity || 0) * (item?.price || 0), 0) || 0),
                      )}
                    </Text>
                  )}
                </Group>
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
      </Flex>
      <Modal
        opened={action[0] === "package" && values?.receiverBusinessId}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="75rem"
        padding={0}>
        <InvoiceProductPackageForm values={values} setFieldValue={setFieldValue} action={action} setAction={setAction} />
      </Modal>
      <Modal
        opened={action[0] === "add" && values?.receiverBusinessId}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="90rem"
        padding={0}
        centered>
        <InvoiceProductItemForm values={values} action={action} setFieldValue={setFieldValue} setAction={setAction} />
      </Modal>
      <Modal
        opened={action[0] === "edit"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="90rem"
        padding={0}
        centered>
        <InvoiceProductItemEditForm action={action} setFieldValue={setFieldValue} setAction={setAction} values={values} />
      </Modal>
    </Paper>
  );
}

const useHeader = ({
  edit,
  setEdit,
  onClick,
}: {
  perTypes: IReference[];
  edit: any;
  setEdit: any;
  onClick: (e: any, e1: any) => void;
  vatTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={`${record?.image || ""}`} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "nameMon",
    render: (record, index) => {
      if (record?.isEdit) {
        return <TextField name={`lines[${index}].nameMon`} placeholder="Бараа, ажил үйлчилгээ" />;
      } else
        return (
          <Text w="max-content" size="sm">
            {record?.nameMon} {record.fullName}
          </Text>
        );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return `${record?.unit || record?.unit || "-"}`;
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record, index) => {
      if (record?.isEdit) return <NumberCurrencyField name={`lines[${index}].price`} placeholder="Нэгжийн үнэ" />;
      else
        return (
          <Text size="sm" ta="end" style={{ whiteSpace: "nowrap" }}>
            {currencyFormat(record?.price ?? 0)}
          </Text>
        );
    },
  },
  {
    title: "НӨАТ(нэгж)",
    dataIndex: "hasVat",
    render: (record) => (
      <Group justify="right">
        <Text w="max-content" size="sm">
          {currencyFormat(record?.vatAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => (
      <Badge color={record.hasTax ? "green.6" : "gray.6"} variant="light" styles={{ label: { overflow: "visible" } }}>
        {record.hasTax ? "Байгаа" : "Байхгүй"}
      </Badge>
    ),
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (record, index) => {
      if (record?.isEdit) return <NumberField size={"xs"} max={9000000} min={0} name={`lines[${index}].quantity`} placeholder="0" />;
      else
        return (
          <Flex align="center" gap={10}>
            <NumberField size={"xs"} max={9000000} min={0} name={`lines[${index}].quantity`} placeholder="Тоо хэмжээ" disabled={edit !== index} w={100} />
            <ActionIcon color={edit === index ? "blue" : "brand"} onClick={() => setEdit(edit !== index ? index : null)} variant="light">
              {edit === index ? <IconDeviceFloppy /> : <IconEdit />}
            </ActionIcon>
          </Flex>
        );
    },
  },
  {
    title: "Барааны нийт үнэ",
    dataIndex: "",
    render: (record) => {
      const count = (record.price ?? 0) * (record.quantity ?? 0);
      return (
        <Text size="sm" ta="end" style={{ whiteSpace: "nowrap" }}>
          {currencyFormat(count)}
        </Text>
      );
    },
  },
  {
    title: "НӨАТ нийт үнэ",
    dataIndex: "",
    render: (record) => {
      const count = (record.vatAmount ?? 0) * (record.quantity ?? 0);
      return (
        <Text size="sm" ta="end" style={{ whiteSpace: "nowrap" }}>
          {currencyFormat(count)}
        </Text>
      );
    },
  },
  {
    title: "Нийт дүн",
    dataIndex: "count",
    render: (record: any) => {
      let count;
      count = ((record.vatAmount || 0) + (record?.price || 0)) * (record?.quantity || 0);
      return (
        <Text ta="end" size="sm" style={{ whiteSpace: "nowrap" }}>
          {currencyFormat(count || 0)}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red">
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];

const useHeader2 = ({
  perTypes,
  edit,
  setEdit,
  onClick,
}: {
  perTypes: IReference[];
  edit: any;
  setEdit: any;
  onClick: (e: any, e1: any) => void;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={`${record?.image || ""}`}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => `${record?.skuCode || "-"}`,
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "nameMon",
    render: (_record, index) => <TextField name={`additionalLines[${index}].name`} placeholder="Бараа, ажил үйлчилгээ" />,
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (_record, index) => {
      return <TextField name={`additionalLines[${index}].unit`} placeholder="Хэмжих нэгж" />;
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (_record, index) => {
      return <NumberCurrencyField name={`additionalLines[${index}].price`} placeholder="Нэгжийн үнэ" />;
    },
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (_record, index) => {
      return <NumberField max={9000000} min={0} name={`additionalLines[${index}].quantity`} placeholder="0" />;
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "count",
    render: (record) => {
      const price = parseFloat(record?.price) || 0;
      const quantity = parseFloat(record?.quantity) || 0;
      const count = price * quantity;

      return <Text w="max-content">{currencyFormat(count)}</Text>;
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <ActionIcon onClick={() => onClick("remove_additionalLines", { ...record, index })} color="red">
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];
