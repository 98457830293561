import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const variantList = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/inv/invoice/variant", { offset, filter });
};

export const variantSelect = async (data: any) => {
  const res = httpRequest.get("/inv/invoice/variant/select", data);
  return res;
};
