import { Anchor, Avatar, Group, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconCaretRight, IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { useScreenDetector } from "../screen-detector";

export const HighestBalanceList = ({ data }: { data: any[] }) => {
  const { invoiceStatus = [], invoicePaymentStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const { classes } = useStyles();
  const { isMobile } = useScreenDetector();

  const [filters] = useState({
    query: "",
    isReceived: false,
  });

  const columns = useHeader({
    myBusinessType,
    invoicePaymentStatus: invoicePaymentStatus,
    invoiceStatus: invoiceStatus,
    onClick: (key, record) => {},
  });

  return (
    <div>
      <Paper withBorder py={"4px"}>
        <Group align="apart">
          <Group align="center">
            <div className={classes.grdnt}></div>
            <Text component="h1">Хамгийн өндөр үлдэгдэлтэй</Text>
          </Group>
          {!isMobile ? (
            <Anchor size={"xs"} px={"xs"}>
              Бүгдийг харах
            </Anchor>
          ) : (
            <Anchor px={"xs"}>
              <IconCaretRight />
            </Anchor>
          )}
        </Group>
        <Paper px={"lg"} style={{ overflow: "auto" }}>
          <Table name="highest.remimber.list" columns={columns} filters={{ ...filters }} dataSource={data} />
        </Paper>
      </Paper>
    </div>
  );
};

const useHeader = ({
  onClick,
  myBusinessType = "",
  invoicePaymentStatus = [],
  invoiceStatus = [],
}: {
  onClick: (key: string, record: IItem) => void;
  myBusinessType: string;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
}): ColumnType<IItem | any>[] => [
  {
    title: "№",
    render: (record, index) => {
      return <div>{index + 1}</div>;
    },
  },
  {
    title: "Партнерийн нэр",
    render: (record) => {
      return (
        <Group w={"max-content"}>
          <Avatar src={record.logo}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text>{record?.partner?.businessName || "-"}</Text>
            <Text size={"sm"} c="brand">
              # {record?.partner?.refCode || "-"}
            </Text>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => {
      return (
        <Group w={"max-content"}>
          <Text>{record?.profileName || "-"}</Text>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх тоо",
    render: (record) => <Text w="max-content">{record?.invoiceCount || 0} ш</Text>,
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    render: (record) => (
      <Group align="right">
        <Text>{currencyFormat(record?.amountToPay || 0)}</Text>
      </Group>
    ),
  },
];

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.colors.brand[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
