import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const business_select = async (data: { partnerId: string; query: string | any } | any) =>
  httpRequest.get("/inv/network_settlement/business_select", data);

export const network = async (data: { filter: { regNumber: string; partnerId: string; businessId: string } } | any) =>
  httpRequest.get("/inv/network_settlement/network", data);

export const partner_select = async (data: any) => httpRequest.get("/inv/network_settlement/partner_select", data);

export const invoice = async (data: any) => httpRequest.get("/inv/network_settlement/invoice", data);
export const history = async (data: { invoiceId: string } | any) => httpRequest.get("/inv/network_settlement/invoice/history", data);
