import { ActionIcon, AppShell, Avatar, Box, Burger, Card, Center, Flex, Group, Menu, Popover, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import {
  IconChevronDown,
  IconFileText,
  IconGridDots,
  IconInbox,
  IconLayoutList,
  IconLogout,
  IconMoneybag,
  IconNetwork,
  IconNotebook,
  IconWallet,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { LogoutApi } from "../../apis";
import { ReactComponent as EraLogo } from "../../assets/logo.svg";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/Message";
import { useScreenDetector } from "../screen-detector";

function removeDuplicates(data: string[]) {
  let unique = data.reduce(function (a: any, b: any) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);

  return unique;
}

export const Header = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes, theme } = useStyles();
  const { user, myBusinessType, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile } = useScreenDetector();
  const myModules = removeDuplicates(user?.permissions?.map((item: any) => item.module) || []);

  const logout = async () => {
    try {
      await LogoutApi.logout();
      window.location.href = "https://cp.dehub.mn/login";
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const mockdata = [
    {
      title: "Network",
      icon: IconNetwork,
      color: "grape",
      link: `https://network.dehub.mn/access?token=${accessToken}`,
      disabled: myModules?.some((item: any) => item === "NETWORK") || false,
    },
    {
      title: "Invoice",
      icon: IconFileText,
      color: "yellow",
      link: `https://invoice.dehub.mn/access?token=${accessToken}`,
      disabled: myModules?.some((item: any) => item === "INVOICE") || false,
    },
    {
      title: "Inventory",
      icon: IconInbox,
      color: "violet",
      link: `https://item.dehub.mn/access?token=${accessToken}`,
      disabled: myModules?.some((item: any) => item === "INVENTORY") || false,
    },
    {
      title: "Payment",
      icon: IconWallet,
      color: "blue",
      link: `https://payment.dehub.mn/access?token=${accessToken}`,
      disabled: myModules?.some((item: any) => item === "PAYMENT") || false,
    },
    {
      title: "Order",
      icon: IconLayoutList,
      color: "cyan",
      link: `https://order.dehub.mn/access?token=${accessToken}`,
      disabled: myModules?.some((item: any) => item === "ORDER") || false,
    },
    {
      title: "Finance",
      icon: IconMoneybag,
      color: "blue",
      link: `https://cp.dehub.mn?verifyConfirmType=modal`,
      disabled: myModules?.some((item: any) => item === "FINANCE") || false,
    },
  ];

  const items = mockdata.map((item) => {
    return (
      <UnstyledButton
        disabled={!item.disabled}
        key={item.title}
        className={classes.item}
        onClick={() => {
          window.location.href = item.link;
        }}
        bg={item.disabled ? item.color + ".0" : "gray.0"}>
        <item.icon color={item.disabled ? theme.colors[item.color][6] : "gray"} size="2rem" />
        <Text size="xs" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    );
  });

  return (
    <AppShell.Header>
      <Group className={classes.header}>
        {!isMobile ? (
          <Flex align={"center"} gap={8}>
            <a href="https://cp.dehub.mn" className={classes.logoBox}>
              <EraLogo className={classes.logo} />
              <span>
                <div className={classes.logoText}>DeHUB</div>
                <div className={classes.logoDesc}>платформ</div>
              </span>
            </a>
            <Box h={"32px"} style={{ borderRight: "1px solid #868E96" }}></Box>

            <Popover width={400} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon size="lg" variant="transparent">
                  <IconGridDots size={"2.0rem"} stroke={1} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Card p={"sm"}>
                  <SimpleGrid cols={3}>{items}</SimpleGrid>
                </Card>
              </Popover.Dropdown>
            </Popover>
            <Text className={classes.moduleText}>Нэхэмжлэх модуль</Text>
          </Flex>
        ) : (
          <div>
            <Flex p={"xs"}>
              <Burger color="gray" size={"sm"} opened={opened} onClick={() => setOpened(!opened)} />
            </Flex>
          </div>
        )}
        <Menu width={260} position="bottom-end" transitionProps={{ duration: 150, transition: "pop-top-right" }}>
          <Menu.Target>
            <UnstyledButton className={classes.user}>
              <Group>
                <Avatar src={user?.avatar} radius="xl" />

                <div style={{ flex: 1 }}>
                  <Text size="sm" fw={500} tt={"capitalize"}>
                    <Center>
                      {user?.lastName ? `${user?.lastName[0]}. ` : ""} {user?.firstName}
                    </Center>
                  </Text>
                  <Text c="dimmed" size="xs">
                    {myBusinessType === "SUPPLIER" ? "Нийлүүлэгч" : "Худалдан авагч"}
                  </Text>
                </div>

                <IconChevronDown size={14} stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                window.location.href = "https://cp.dehub.mn/auth/profile/dan";
              }}
              leftSection={<IconNotebook size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
              Миний мэдээлэл
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => logout()} color="red" leftSection={<IconLogout size={20} stroke={1.5} />}>
              Гарах
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </AppShell.Header>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: theme.colors.brand[0],
    height: "100%",
    borderBottom: "4px solid #FF854B",
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
  },
  logo: {
    height: 32,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.colors.brand,
    lineHeight: "18px",
  },
  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.colors.brand,
    textTransform: "uppercase",
  },
  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },
  user: {
    padding: theme.spacing.xs,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  userActive: {
    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[8] : theme.white,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
