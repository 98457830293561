import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: { filter: { settlementId: string } } | any) => {
  return httpRequest.get(`/inv/settlement/invoice`, data);
};

export const history = async (data: { filter: { invoiceId: string } } | any) => {
  return httpRequest.get(`/inv/settlement/invoice/history`, data);
};
