import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const select = async (data: any) => {
  return httpRequest.get("/inv/network/select", data);
};

export const get = async (id: any) => {
  return httpRequest.get(`/inv/network/${id}`);
};

export const business_select = async (data: any) => {
  return httpRequest.get("/inv/invoice/history/business_select", data);
};
